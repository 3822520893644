
<template>
  <div>
    <!--pc-->
    <div
      class="d-md-block d-none"
      :class="{'banner-cont-rc': dataList[0].fromProduct}"
      style="height: 100%; position: relative"
      v-if="screenWidth > 750"
    >
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        v-if="dataList.length > 1"
      >
        <div
          :class="{'banner-cont-rc-item': item.fromProduct}"
          v-for="item in dataList" :key="item.id">
          <b-carousel-slide class="banner">
            <template #img>
              <!-- alt="image slot" -->
              <b-link
                :disabled="item.fromProduct"
                class="banner-cont-rc-single-l"
                v-if="item.imgUrl" :href="item.linkUrl || '#'">
                <b-img
                  :src="item.imgUrl"
                  :alt="item.advertisementName "
                  :title="item.advertisementName "
                  fluid
                  style="width: 100%; height: auto; max-height: 830px"
                >
                </b-img>
              </b-link>
              <b-link
                :disabled="item.fromProduct"
                class="banner-cont-rc-single-l"
                v-if="item.videoUrl" :href="item.linkUrl || '#'">
                <div
                  id="banner-video3"
                  style="
                    background-color: rgb(0, 0, 0);
                    overflow: hidden;
                    position: relative;
                    height: 100%;
                  "
                  class="video-div"
                >
                  <video
                    id="chldasemvbetrprzfu3"
                    :src="item.videoUrl"
                    width="100%"
                    muted="“muted”"
                    autoplay="autoplay"
                    :poster="
                      item.videoUrl +
                      '?x-oss-process=video/snapshot,t_3000,f_jpg'
                    "
                    x5-playsinline=""
                    playsinline=""
                    webkit-playsinline="true"
                    x5-video-player-fullscreen="true"
                    loop
                    style="
                      width: 100%;
                      background-color: rgb(0, 0, 0);
                      object-fit: fill;
                    "
                  ></video>
                </div>
              </b-link>
              <div class="banner-cont1 banner-cont-rc-single-r">
                <div class="wenzi">
                  {{ item.name }}
                </div>
                <div class="sub-wenzi" v-if="!item.fromProduct">
                  {{ item.subTitle }}
                </div>
                <div v-else v-html="item.details"></div>
              </div>
            </template>
          </b-carousel-slide>
        </div>
      </b-carousel>
      <!-- 单张时不显示轮播 -->
      <b-link
        :disabled="dataList[0].fromProduct"
        class="single-cal banner-cont-rc-single-l"
        v-if="dataList.length == 1" :href="dataList[0].linkUrl || '#'">
        <b-img
          :src="dataList[0].imgUrl"
          fluid
          :alt="dataList[0].advertisementName "
          :title="dataList[0].advertisementName "
          style="width: 100%; height: auto; max-height: 830px"
          v-if="dataList[0].imgUrl"
        >
        </b-img>
        <div
          id="banner-video"
          style="
            background-color: rgb(0, 0, 0);
            overflow: hidden;
            position: relative;
            height: 100%;
          "
          class="video-div"
          v-if="dataList[0].videoUrl"
        >
          <video
            id="chldasemvbetrprzfu"
            :src="dataList[0].videoUrl"
            width="100%"
            muted="“muted”"
            autoplay="autoplay"
            :poster="
              dataList[0].videoUrl +
              '?x-oss-process=video/snapshot,t_3000,f_jpg'
            "
            x5-playsinline=""
            playsinline=""
            webkit-playsinline="true"
            x5-video-player-fullscreen="true"
            loop
            style="
              width: 100%;
              height: auto;
              background-color: rgb(0, 0, 0);
              object-fit: fill;
            "
          ></video>
        </div>
      </b-link>
      <div
        class="banner-cont1 banner-cont-rc-single-r"
        v-if="dataList.length == 1">
        <div class="wenzi">
          {{ dataList[0].name }}
        </div>
        <div class="sub-wenzi" v-if="!dataList[0].fromProduct">
          {{ dataList[0].subTitle }}
        </div>
        <div v-else v-html="dataList[0].details"></div>
      </div>
    </div>
    <!--h5-->
    <div
      :class="{'banner-cont-rc': dataList[0].fromProduct}"
      class="d-md-none d-block" v-if="screenWidth <= 750">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="4000"
        controls
        indicators
        img-height="830px"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        v-if="dataList.length > 1"
      >
        <div
          :class="{'banner-cont-rc-item': item.fromProduct}"
          v-for="item in dataList" :key="item.id">
          <b-carousel-slide class="banner" :key="item.id">
            <template #img>
              <b-link
                :disabled="item.fromProduct"
                class="banner-cont-rc-single-l"
                v-if="item.imgUrl" :href="item.linkUrl || '#'">
                <b-img
                  :src="item.appImgUrl || item.imgUrl"
                  fluid
                  alt="image slot"
                  style="width: 100%; height: 100%"
                >
                </b-img>
              </b-link>
              <b-link
                :disabled="item.fromProduct"
                class="banner-cont-rc-single-l"
                v-if="item.videoUrl" :href="item.linkUrl || '#'">
                <div
                  id="banner-video1"
                  style="
                    background-color: rgb(0, 0, 0);
                    overflow: hidden;
                    position: relative;
                    height: 100%;
                  "
                  class="video-div"
                >
                  <video
                    id="chldasemvbetrprzfu1"
                    :src="item.videoUrl"
                    width="100%"
                    muted="“muted”"
                    autoplay="autoplay"
                    poster="https://www.ruijienetworks.com/aj/about/img/banner-aboutus.png"
                    x5-playsinline=""
                    playsinline=""
                    webkit-playsinline="true"
                    x5-video-player-fullscreen="true"
                    loop
                    style="
                      width: 100%;
                      background-color: rgb(0, 0, 0);
                      object-fit: fill;
                    "
                  ></video>
                </div>
              </b-link>
              <div class="banner-cont1 banner-cont-rc-single-r">
                <div class="wenzi">
                  {{ item.name }}
                </div>
                <div class="sub-wenzi" v-if="!item.fromProduct">
                  {{ item.subTitle }}
                </div>
                <div v-else v-html="item.details"></div>
              </div>
            </template>
          </b-carousel-slide>
        </div>
      </b-carousel>
      <!-- 单张时不显示轮播 -->
      <b-link
        :disabled="dataList[0].fromProduct"
        class="banner-cont-rc-single-l"
        v-if="dataList.length == 1" :href="dataList[0].linkUrl || '#'">
        <b-img
          :src="dataList[0].appImgUrl || dataList[0].imgUrl"
          fluid
          alt="image slot"
          style="width: 100%; height: auto; max-height: 830px"
          v-if="dataList[0].imgUrl"
        >
        </b-img>
        <div
          id="banner-video2"
          style="
            background-color: rgb(0, 0, 0);
            overflow: hidden;
            position: relative;
            height: 100%;
          "
          class="video-div"
          v-if="dataList[0].videoUrl"
        >
          <video
            id="chldasemvbetrprzfu2"
            :src="dataList[0].videoUrl"
            width="100%"
            muted="“muted”"
            autoplay="autoplay"
            x5-playsinline=""
            playsinline=""
            webkit-playsinline="true"
            x5-video-player-fullscreen="true"
            loop
            style="
              height: auto;
              background-color: rgb(0, 0, 0);
              object-fit: fill;
            "
          ></video>
        </div>
      </b-link>
      <div class="banner-cont1 banner-cont-rc-single-r" v-if="dataList.length == 1">
        <div class="wenzi">
          {{ dataList[0].name }}
        </div>
        <div class="sub-wenzi" v-if="!dataList[0].fromProduct">
          {{ dataList[0].subTitle }}
        </div>
        <div v-else v-html="dataList[0].details"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    height: {
      type: String,
    },
    dataList: {
      type: Array,
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  computed: {
    ...mapMutations(["setScreenWidth"]),
    ...mapState(["screenWidth"]),
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
    this.$nextTick(() => {
      //页面加载头
      this.$nuxt.$loading.start();
    });
    setTimeout(() => this.$nuxt.$loading.finish(), 1000);
    // this.getData();
    // this.getBanner();
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
/*::v-deep .img-fluid{*/
/*height: 600px;*/
/*}*/
::v-deep .carousel-indicators .active {
  background: $red-color !important;
  height: 4px;
}
::v-deep .carousel-indicators li {
  background: #ffffff;
  opacity: 1;
  border: none;
  height: 4px;
}
::v-deep .el-carousel__arrow--left {
  background-image: url("~@/static/img/public/banner-left.png");
  margin-left: 83px;
}
::v-deep .el-carousel__arrow--right {
  background-image: url("~@/static/img/public/banner-right.png");
  margin-right: 83px;
}
::v-deep .el-carousel__button {
  background: #ffffff !important;
  opacity: 1;
}
::v-deep .el-carousel__indicator.is-active button {
  background: $red-color !important;
}

::v-deep .carousel-control-prev-icon {
  background-image: url("~@/static/img/public/banner-left.png");
  margin-left: 83px;
  height: 64px;
  width: 64px;
}

::v-deep .carousel-control-next-icon {
  background-image: url("~@/static/img/public/banner-right.png");
  margin-left: 83px;
  height: 64px;
  width: 64px;
}

::v-deep .el-carousel__arrow {
  height: 64px;
  width: 64px;
}
::v-deep .el-icon-arrow-left {
  display: none;
  left: 83px;
}
::v-deep .el-icon-arrow-right {
  display: none;
  right: 81px;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.banner-cont1 {
  position: absolute;
  left: 0;
  top: calc(50% - 80px);
  z-index: 3;
  width: 100%;
  text-align: left;
  padding-left: 150px;
  .wenzi {
    font-size: 55px;
    color: #fff;
    margin-bottom: 10px;
  }
  .sub-wenzi {
    font-size: 30px;
    color: #fff;
  }
}
@media (max-width: 750px) {
  .banner-cont1 {
    top: calc(50% - 120px) !important;
    text-align: center;
    padding-left: 0;
    .wenzi {
      font-size: 24px;
    }
    .sub-wenzi {
      font-size: 18px;
    }
  }
}
.single-cal :hover{
  cursor: default !important;
}
.banner-cont-rc-single-l{
  img{
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
  }
}
// 分类用
.banner-cont-rc{
  overflow: hidden;
  margin-top: 30px;
  .banner-cont-rc-single-l{
    width: 30%;
    float: left;
    padding: 80px;
  }
  .banner-cont-rc-single-r{
    position: relative;
    width: 70%;
    float: left;
    padding-left: 0;
    padding-top: 30px;
    .wenzi,
    .sub-wenzi{
      color: #333;
    }
    .wenzi{
      font-size: 30px;
    }
    .sub-wenzi{
      font-size: 14px;
    }
  }
  &.d-md-none{
    .banner-cont-rc-single-l,
    .banner-cont-rc-single-r{
      width: 100%;
    }
    .banner-cont-rc-single-l{
      padding: 0;
    }
  }
}
</style>
