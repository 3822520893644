<template>
  <footer class="text-center" style="border-top: 1px solid #e0e0e0">
    <!-- pc -->
    <div class="d-md-block d-none" v-if="screenWidth > 750">
      <div class="container footer-top">
        <div class="social-media-info-pc">
          <a href="https://www.linkedin.com/company/ruijie-networks-japan%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE/"
             target="_blank">
            <svg width="25.973145" height="25.974609" viewBox="0 0 25.9731 25.9746" fill="#000000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M23.3176 0L2.65552 0C1.19019 0 0 1.18878 0 2.65692L0 23.3192C0 24.7858 1.18884 25.9746 2.65552 25.9746L23.3176 25.9746C24.7844 25.9746 25.9731 24.7901 25.9731 23.3192L25.9731 2.65692C25.9761 1.18878 24.7874 0 23.3176 0ZM5.58154 3.01355C7.01831 3.01355 8.18115 4.07916 8.18115 5.39117C8.18115 6.70026 7.01831 7.76587 5.58154 7.76587C4.14502 7.76587 2.98206 6.70026 2.98206 5.39117C2.97913 4.07916 4.14648 3.01355 5.58154 3.01355ZM8.44189 22.9639L2.72131 22.9639L2.72131 9.58917L8.44189 9.58917L8.44189 22.9639ZM22.9683 22.8981L18.624 22.8981L18.624 15.7667C18.624 14.3502 17.676 13.1083 16.1577 13.1083C14.6379 13.1083 14.0837 14.2656 14.0837 15.3499C14.0837 16.8123 14.0837 22.8995 14.0837 22.8995L9.73828 22.8995L9.73828 9.65363L14.0837 9.65363L14.0837 11.6144C14.7124 10.7278 16.311 9.61212 18.011 9.61212C20.1294 9.61212 20.5649 10.0232 21.4385 10.894C23.0957 12.5526 22.9409 14.8701 22.9653 15.5762L22.9683 22.8981Z" fill-rule="evenodd" />
            </svg>
          </a>
          <a href="https://www.youtube.com/channel/UCK7s9EAAq0wIFw4kr7OjBiw"
             target="_blank">
            <svg width="33.250000" height="23.304688" viewBox="0 0 33.25 23.3047" fill="#000000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M32.5562 3.64044C32.1738 2.20801 31.0457 1.0799 29.6169 0.697632C27.0229 0 16.625 0 16.625 0C16.625 0 6.22705 0 3.63306 0.69397C2.20068 1.07617 1.07617 2.20428 0.693848 3.63672C0 6.23438 0 11.6523 0 11.6523C0 11.6523 0 17.0703 0.693848 19.6642C1.07617 21.0967 2.20435 22.2248 3.63306 22.6071C6.22705 23.3047 16.625 23.3047 16.625 23.3047C16.625 23.3047 27.0229 23.3047 29.6169 22.6071C31.0493 22.2248 32.1738 21.0967 32.5562 19.6642C33.25 17.0703 33.25 11.6523 33.25 11.6523C33.25 11.6523 33.25 6.23438 32.5562 3.64044ZM13.3223 16.625L13.3223 6.67969L21.9316 11.6152L13.3223 16.625Z" fill-rule="evenodd" />
            </svg>
          </a>
        </div>
        <b-row cols="1" no-gutters class="mx-0">
          <b-col md="2"  class="wow" style="margin-bottom: 20px">
            <div class="item1">
              <a class="span1" href="/products"> 製品情報</a>
              <!-- <div
                v-for="item1 in classfyList"
                :key="item1.id"
                class="sumb-item"
              >
                <b-link
                  :to="`/products?cateId=` + item1.id"
                  v-if="item1.id != undefined"
                >
                  {{ item1.name }}
                </b-link>
              </div> -->
              <b-link
                :to="item1.toLink"
                v-for="item1 in classfyList"
                :key="item1.id"
                class="sumb-item"
              >
                {{ item1.name }}
              </b-link>
            </div>
          </b-col>
          <b-col md="3" class="wow" style="margin-bottom: 20px">
            <div class="item1">
              <a class="span1" href="/solutions"> {{ footerList[1].title }}</a>
              <b-link
                :to="item.webUrl"
                class="sumb-item"
                v-for="(item, index) in solutionList"
                :key="index"
              >
                <!-- マンションISP向けソリューション -->
                {{ item.name }}
                <!-- <h3 style="font-size:12px"> {{item.name}}</h3> -->
              </b-link>
            </div>
          </b-col>
          <b-col md="2"  class="wow" style="margin-bottom: 20px">
            <div class="item1">
              <a class="span1" href="/service"> {{ footerList[2].title }}</a>
              <b-link
                v-for="item1 in footerList[2].subTitleList"
                :key="item1.id"
                :to="item1.toLink"
                class="sumb-item"
              >
                {{ item1.name }}
              </b-link>
            </div>
          </b-col>
          <b-col md="2" class="wow" style="margin-bottom: 20px">
            <div class="item1">
              <a class="span1" href="/company"> {{ footerList[3].title }}</a>
              <b-link
                v-for="item1 in footerList[3].subTitleList"
                :key="item1.id"
                :to="item1.toLink"
                class="sumb-item"
              >
                {{ item1.name }}
              </b-link>
            </div>
          </b-col>
          <b-col md="2"  class="wow" style="margin-bottom: 20px">
            <div class="item1">
              <span class="span2" > {{ footerList[4].title }}</span>
              <b-link
                v-for="item1 in footerList[4].subTitleList"
                :key="item1.id"
                :to="item1.toLink"
                class="sumb-item"
              >
                {{ item1.name }}
              </b-link>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="footer-bottom-pc">
        <div class="footer-bottom-pc-wrap">
          <div>
            <a href="https://www.ruijie.co.jp/privacy-policy" target="_blank">プライバシーポリシー</a>
<!--            <el-divider direction="vertical"></el-divider>-->
<!--            <a href="javascript:;" target="_blank">サイトマップ</a>-->
          </div>
          <span>Copyright© 2000 - 2024 Ruijie. All Rights Reserved.</span>
        </div>
      </div>
    </div>

    <!-- h5 -->
    <div
      class="d-md-none d-block"
      style="padding: 20px 0 0 0"
      v-if="screenWidth <= 750"
    >
      <el-collapse style="width: 100%">
        <el-collapse-item title="製品情報">
          <b-link
            v-for="item1 in classfyList"
            :key="item1.id"
            class="sumb-item fub-name"
            :href="`/products?cateId=` + item1.id"
          >
            {{ item1.name }}
          </b-link>
        </el-collapse-item>
      </el-collapse>
      <el-collapse style="width: 100%">
        <el-collapse-item :title="footerList[1].title">
          <b-link
            :to="item.jumpUrlName"
            class="sumb-item fub-name"
            v-for="(item, index) in solutionList"
            :key="index"
          >
            {{ item.name }}
          </b-link>
        </el-collapse-item>
      </el-collapse>
      <el-collapse style="width: 100%">
        <el-collapse-item :title="footerList[2].title">
          <b-link
            v-for="item1 in footerList[2].subTitleList"
            :key="item1.id"
            :to="item1.toLink"
            class="sumb-item fub-name"
            style="display: block"
          >
            {{ item1.name }}
          </b-link>
        </el-collapse-item>
      </el-collapse>
      <el-collapse style="width: 100%">
        <el-collapse-item :title="footerList[3].title">
          <b-link
            v-for="item1 in footerList[3].subTitleList"
            :key="item1.id"
            :to="item1.toLink"
            class="sumb-item fub-name"
            style="display: block"
          >
            {{ item1.name }}
          </b-link>
        </el-collapse-item>
      </el-collapse>
      <el-collapse style="width: 100%">
        <el-collapse-item :title="footerList[4].title">
          <b-link
            v-for="item1 in footerList[4].subTitleList"
            :key="item1.id"
            :to="item1.toLink"
            class="sumb-item fub-name"
            style="display: block"
          >
            {{ item1.name }}
          </b-link>
        </el-collapse-item>
      </el-collapse>
      <div class="footer-bottom-h5">
        <div class="footer-bottom-h5-wrap">
          <div class="social-media-info-h5">
            <a href="https://www.linkedin.com/company/ruijie-networks-japan%E6%A0%AA%E5%BC%8F%E4%BC%9A%E7%A4%BE/"
               target="_blank">
              <svg width="25.973145" height="25.974609" viewBox="0 0 25.9731 25.9746" fill="#000000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path d="M23.3176 0L2.65552 0C1.19019 0 0 1.18878 0 2.65692L0 23.3192C0 24.7858 1.18884 25.9746 2.65552 25.9746L23.3176 25.9746C24.7844 25.9746 25.9731 24.7901 25.9731 23.3192L25.9731 2.65692C25.9761 1.18878 24.7874 0 23.3176 0ZM5.58154 3.01355C7.01831 3.01355 8.18115 4.07916 8.18115 5.39117C8.18115 6.70026 7.01831 7.76587 5.58154 7.76587C4.14502 7.76587 2.98206 6.70026 2.98206 5.39117C2.97913 4.07916 4.14648 3.01355 5.58154 3.01355ZM8.44189 22.9639L2.72131 22.9639L2.72131 9.58917L8.44189 9.58917L8.44189 22.9639ZM22.9683 22.8981L18.624 22.8981L18.624 15.7667C18.624 14.3502 17.676 13.1083 16.1577 13.1083C14.6379 13.1083 14.0837 14.2656 14.0837 15.3499C14.0837 16.8123 14.0837 22.8995 14.0837 22.8995L9.73828 22.8995L9.73828 9.65363L14.0837 9.65363L14.0837 11.6144C14.7124 10.7278 16.311 9.61212 18.011 9.61212C20.1294 9.61212 20.5649 10.0232 21.4385 10.894C23.0957 12.5526 22.9409 14.8701 22.9653 15.5762L22.9683 22.8981Z" fill-rule="evenodd" />
              </svg>
            </a>
            <a href="https://www.youtube.com/channel/UCK7s9EAAq0wIFw4kr7OjBiw"
               target="_blank">
              <svg width="33.250000" height="23.304688" viewBox="0 0 33.25 23.3047" fill="#000000" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path d="M32.5562 3.64044C32.1738 2.20801 31.0457 1.0799 29.6169 0.697632C27.0229 0 16.625 0 16.625 0C16.625 0 6.22705 0 3.63306 0.69397C2.20068 1.07617 1.07617 2.20428 0.693848 3.63672C0 6.23438 0 11.6523 0 11.6523C0 11.6523 0 17.0703 0.693848 19.6642C1.07617 21.0967 2.20435 22.2248 3.63306 22.6071C6.22705 23.3047 16.625 23.3047 16.625 23.3047C16.625 23.3047 27.0229 23.3047 29.6169 22.6071C31.0493 22.2248 32.1738 21.0967 32.5562 19.6642C33.25 17.0703 33.25 11.6523 33.25 11.6523C33.25 11.6523 33.25 6.23438 32.5562 3.64044ZM13.3223 16.625L13.3223 6.67969L21.9316 11.6152L13.3223 16.625Z" fill-rule="evenodd" />
              </svg>
            </a>
          </div>
          <div style="margin: 20px 0;">
            <a href="https://www.ruijie.co.jp/privacy-policy" target="_blank">プライバシーポリシー</a>
<!--            <el-divider direction="vertical"></el-divider>-->
<!--            <a href="javascript:;" target="_blank">サイトマップ</a>-->
          </div>
          <span>Copyright© 2000 - 2024 Ruijie. All Rights Reserved.</span>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
import { solutionList } from "@/api/api.js";

import { mapMutations, mapState } from "vuex";

export default {
  props: {
    classfyList: {
      type: Array,
    },
    solutionList: {
      type: Array,
    },
  },
  data() {
    return {
      footerList: [
        {
          title: "製品情報",
          subTitleList: [
            { name: "スイッチ", toLink: "/products/switches" },
            { name: "無線LAN", toLink: "/products" },
            { name: "ルーター", toLink: "/products" },
          ],
        },
        {
          title: "ソリューション",
          subTitleList: [
            { name: "マンションISP向けソリューション", toLink: "/solution" },
            { name: "データセンターソリューション", toLink: "/solution" },
          ],
        },
        {
          title: "サポート",
          subTitleList: [
            {
              name: "法人様専用　故障・修理のお申し込み",
              toLink: "/service/post-sales",
            },
            { name: "ダウンロード", toLink: "/downloads" },
            // { name: "ブログ", toLink: "/blog" },
            // { name: "よくある質問", toLink: "/faq" },
            { name: "お問い合わせ", toLink: "/sales" },
            { name: "故障対応プロセス", toLink: "/service/aftersale-flow" },
          ],
        },
        {
          title: "企業情報",
          subTitleList: [
            { name: "トップメッセージ", toLink: "/company/leader" },
            { name: "会社概要", toLink: "/company/profile" },
            // { name: "Ruijie Networks Japanについて", toLink: "/company" },
            { name: "Ruijie Networksについて", toLink: "/about-us" },
            { name: "採用情報", toLink: "/careers" },
          ],
        },
        {
          title: "もっと学ぶ",
          subTitleList: [
            { name: "ニュース", toLink: "/news" },
            { name: "ブログ", toLink: "/blog" },
            { name: "よくある質問", toLink: "/faq" },
            { name: "技術知識", toLink: "/technology" },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapMutations(["setScreenWidth"]),
    ...mapState(["screenWidth"]),
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
  },
  methods: {
    jumpNext(item) {
      this.$router.push(item.toLink);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";

a {
  /* color: #007bff; */
  text-decoration: none;
  display: block;
  width: 100%;
  color: $nav-black-color;
  line-height: 29px;
  font-size: 12px;
  color: #7d7d7d;
  &:hover {
    text-decoration: none;
  }
}

.footer-top {
  padding: 74px 0 38px 0;
  background: #ffffff;
  height: auto;
}
.item1 {
  display: flex;
  flex-direction: column;

  span {
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 12px;
    color: #7d7d7d;
    letter-spacing: 0;
    line-height: 29px;
  }
  .span1 {
    font-size: 14px;
    color: $nav-black-color;
    padding-bottom: 9px;
    &:hover {
      color: $red-color;
    }
  }
  .span2 {
    font-size: 14px;
    color: $nav-black-color;
    padding-bottom: 9px;
  }
}
.footer-bottom-pc, .footer-bottom-h5 {
  height: 60px;
  width: 100%;
  background: #f1f3f5;
  font-size: 12px !important;
  color: $nav-black-color;
  .el-divider {
    background-color: $nav-black-color;
    margin: 0 20px;
  }
  a {
    width: auto;
    display: inline-block;
    color: $nav-black-color;
    &:hover {
      color: $red-color;
    }
  }
}
.footer-bottom-h5{
  height: auto;
  .footer-bottom-h5-wrap {
    padding-bottom: 20px;
  }
  a {
    width: auto;
    color: $nav-black-color;
  }
}
.footer-bottom-pc-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  height: 100%;
}
.sumb-item:hover {
  color: $red-color;
  cursor: pointer;
}
.fub-name {
  height: 40px;
  line-height: 40px;
  text-align: left;
  padding-left: 30px;
}
.row{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.social-media-info-pc, .social-media-info-h5{
  a {
    width: auto;
    & + a {
      margin-left: 25px;
    }
    &:hover {
      svg {
        fill: #555;
      }
    }
  }
}
.social-media-info-pc {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
  margin-bottom: 20px;
  border: 1px solid $nav-defalut-color;
  border-right: none;
  border-left: none;
}
.social-media-info-h5 {
  margin-top: 20px;
  padding-top: 20px;
}
</style>
