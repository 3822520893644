const reqApi = {
  //新闻分类列表
  getNewsClassfyData: '/api/news/list',
  
  //新闻列表
  getNewsList: '/api/news/pageList',
  
  // bannerurl
  getBanner: '/api/banner/list/',
  
  //首页热门商品
  getHotProduct: '/api/goods/index',
  
  //产品分类
  getProductType: '/api/goods/category',
  
  //获取方案列表
  getSolutionList: '/api/solution/pageList',
  
  //锐捷优越性
  getRuijiStorge: '/api/company/superiority',
  
  //技术专题
  getTechnologyList: '/api/topic/pageList',
  
  //获取导入事例
  getCaseList: '/api/case/pageList',
  
  //获取导入事例分类
  getCateList: '/api/case/cateList/',
  
  //获取协议信息 协议分类 21--隐私协议 22-售后服务 23--加入我们 24--在日本市场的优越性 25--企业情报 26--RuiJieNetworks
  getSingleType: '/api/single/info/',
  
  //企业荣誉
  getCompanyHonor: '/api/company/honor',
  
  //获取锐捷合作伙伴
  getRuijiePartner: '/api/company/partner',
  
  //获取企业文化
  getCompanyCulture: '/api/company/culture',
  
  //获取企业信息
  getCompanyInfo: '/api/company/info',
  
  //获取企业发展史
  getCompanyHistory: '/api/company/history/',
  
  //企业创新
  getCompanyInnovate: '/api/company/innovate/',
  
  //全球活动
  getGlobalActive: '/api/company/global',
  
  //招聘列表
  getRecruitList: '/api/recruit/pageList',
  
  //获取左边下载数据
  getLeftDownFile: '/api/goods/fileListSku',

  getLeftDownFileNew: '/api/goods/fileListSkuNew',
  
  //获取右边下载数据
  getRightDownFile: '/api/goods/fileListNew',
  
  //获取咨询问题
  getConsultQuesList: '/api/consult/quesList',
  
  //服务与支持
  getServiceSupport: '/api/service/info/',
  
  //获取招聘详情
  getJobListingsDetail: '/api/recruit/info/',
  
  //获取新闻详情数据
  getNewsDetail: '/api/news/info/',
  
  //获取事例详情
  getCaseInfo: '/api/case/info/',
  
  //获取技术详情
  getTechnologyInfo: '/api/topic/info/',
  
  //获取产品的video和img
  getProductVideoAndImg: '/api/goods/imageAndVideo/',
  
  //获取产品描述的
  getProductDesc: '/api/goods/desc/',
  
  //获取产品的基础信息
  getBasicInformation: '/api/goods/detail/',
  
  //获取技术参数数据
  getTechnicalData: '/api/goods/technicalParam/',
  
  //获取产品文档分类
  getProductCateData: '/api/goods/categoryList/',
  
  //获取产品文档列表
  getProductDocumentList: '/api/goods/dataDownload',
  
  //产品sku详情
  getProductSkuModel: '/api/goods/getGoodsSkuModel/',
  
  //产品参数对比数据
  getProductComparison: '/api/goods/getGoodsAttrContract/',
  
  //产品列表
  getGoodsList: '/api/goods/list',
  
  //产品问题
  getGoodsQues: '/api/goods/proQues/',
  
  //合作伙伴列表
  partnerList: '/api/channel/partner/list',
  
  //合作伙伴招募
  partnerRecruit: '/api/channel/partner/recruit',
  
  //blog热门列表
  blogHotList: '/api/blog/hotList',
  
  //blog分页列表
  blogPageList: '/api/blog/pageList',
  
  //blog详情
  blogDetail: '/api/blog/info/',
  
  //推荐列表
  blogRecommond: '/api/blog/topList',
  
  //faq热门列表
  faqHotList: '/api/faq/hotList',
  
  //faq分页列表
  faqPageList: '/api/faq/pageList',
  
  //faq详情
  faqDetail: '/api/faq/info/',
  
  //推荐列表
  faqRecommond: '/api/faq/topList',
  
  //获取商品分类
  getGoodsScreenList: '/api/goods/getGoodsScreenList/',
  
  //根据商品分类查数据
  getListByClass: '/api/goods/listNew/',
  /**
   * 新增
   * */
  // 技术专题热门列表
  technologyHotList: '/api/topic/hotList',
  // 技术专题列表
  technologyRecommond: '/api/topic/topList',
  
  // 标签分页列表
  tagsList: '/api/aggregate/pageList',
  // 热门标签 GET
  tagsHotLabelList: '/api/aggregate/hotLabelList',
  // 热门列表 GET
  tagsHotList: '/api/aggregate/hotList',
  // 详情 GET
  tagsDetail: '/api/aggregate/info/',
  // 标签详情(SEO用) GET
  tagsSEODetail: '/api/aggregate/labelInfo/',
  
  // 产品分类关联信息
  goodsCategoryInfo: '/api/goods/getCategoryRelate/',
  
  // 保修查询列表
  serviceQueryGuaranteeList: '/api/service/queryGuarantee',
  
  // dataSheet
  dataSheet: '/api/goods/dataSheet/',
  
  // sem 产品列表
  semProductsList: '/api/goods/listMap',
  // 获取FAQ列表
  getFaqList:'/api/goods/categoryFaq'
};
export default reqApi;
