import seoRouteConfig from '@/config/seoRouteConfig.js' //对状态码抽离的配置文件
const ip = "https://www.ruijie.co.jp";
import error from "@/layouts/error.vue"
// 可以正常访问，canonical标签指向错误链接集合
/* let canonicalLink = {
    "/news/newsDetail?id=432105085109796864":"/news/2021-05-26_432105085109796864.html",
    "/products/info?productId=432111497256108032":"/products/rg-xs-s1930j-8gt2sfp-p_p432111497256108032.html",
    "/products/info?productId=432111650928590848&from=1":"/products/rg-ap180-pe_p432111650928590848.html",
    "/products/info?productId=432112137901965312&from=1":"/products/rg-s6980-64qc_p432112137901965312.html",
    "/products/info?productId=432127722311909376&from=1":"/products/rg-ap680-cd-jp_p432127722311909376.html",
    "/products/info?productId=432157640801386496&from=1":"/products/rg-s6520-64cq_p432157640801386496.html",
    "/products/info?productId=432157859296575488&from=1":"/products/rg-s6510-48vs8cq_p432157859296575488.html",
    "/products/info?productId=432196332852871168&from=1":"/products/rg-s5750c-48gt4xs-h_p432196332852871168.html",
    "/products/info?productId=432221793654669312&from=1":"/products/rg-eg2100-p-v2_p432221793654669312.html",
    "/products/info?productId=432334540973015040":"/products/rg-ma2810_p432334540973015040.html",
    "/products/info?productId=432585132419842048":"/products/xs-s1930j-8gt2sfp_p432585132419842048.html",
    "/products/info?productId=432585132419842048&from=1":"/products/xs-s1930j-8gt2sfp_p432585132419842048.html",
    "/products/info?productId=432585440843792384":"/products/xs-s1930j-18gt2sfp_p432585440843792384.html",
    "/products/info?productId=432585489170300928":"/products/xs-s1930j-18gt2sfp-p_p432585489170300928.html",
    "/products/info?productId=432585489170300928&from=1":"/products/xs-s1930j-18gt2sfp-p_p432585489170300928.html",
    "/products/info?productId=432585532103983104":"/products/xs-s1930j-24gt4sfp-2gt_p432585532103983104.html",
    "/products/info?productId=432585618671534080":"/products/xs-s1930j-24gt4sfp-2gt-p_p432585618671534080.html",
    "/products/info?productId=432585618671534080&from=1":"/products/xs-s1930j-24gt4sfp-2gt-p_p432585618671534080.html",
    "/products/info?productId=432585663966609408":"/products/xs-s1930j-48gt4sfp_p432585663966609408.html",
    "/products/info?productId=432629990879657984":"/products/rg-ap180-ja-jp_p432629990879657984.html",
    "/products/info?productId=432629990879657984&from=1":"/products/rg-ap180-ja-jp_p432629990879657984.html",
    "/products/info?productId=432629990879657984&from=2":"/products/rg-ap180-ja-jp_p432629990879657984.html",
    "/products/info?productId=432784353972977664&from=1":"/products/rg-ma2610-ac_p432784353972977664.html",
    "/products/info?productId=432784401508073472":"/products/rg-ma2610-pe_p432784401508073472.html",
    "/products/info?productId=432784401508073472&from=1":"/products/rg-ma2610-pe_p432784401508073472.html",
    "/products/info?productId=432110275305668608":"/products/rg-ap180-ac_p432110275305668608.html",
    "/products/info?productId=432134337127776256":"/products/rg-n18000-x_p432134337127776256.html",
    "/products/info?productId=432112356253499392":"/products/rg-s6580-48cq8qc_p432112356253499392.html",
    "/products/info?productId=432112675328622592":"/products/rg-s6920-4c_p432112675328622592.html",
    "/products/info?productId=432157985548795904":"/products/rg-s6510-32cq_p432157985548795904.html",
    "/products/info?productId=432158038448668672":"/products/rg-s6250-48xs8cq_p432158038448668672.html",
    "/products/info?productId=432091214928412672":"/products/rg-s6120-20xs4vs2qxs_p432091214928412672.html",
    "/products/info?productId=432202362292797440":"/products/rg-s2910-24gt4sfp-up-h_p432202362292797440.html",
    "/products/info?productId=432127639865786368":"/products/rg-ap850-i-jpv2_p432127639865786368.html",
    "/products/info?productId=432036690761613312":"/products/rg-ap820-l_p432036690761613312.html",
    "/products/info?productId=432382670957445120":"/products/rg-ws6008_p432382670957445120.html",
    "/products/info?productId=432382462915772416":"/products/rg-eg3230_p432382462915772416.html",
    "/products/info?productId=432109425563271168":"/products/rg-eg3250_p432109425563271168.html"
} */
let isCanonical=false;
export default function ({
  req,
  res,
  route,
  app,
  redirect,
  rewrite,
  $axios,
}) {
  for(let i=0;i<app.head.link.length;i++){
    if(app.head.link[i].rel="canonical"){
      app.head.link.splice(i,1)
      i--;
    }
  }
  seoRouteConfig.forEach(item => {
      let currentPath; // 保存路径地址
      // 不同的路径地址
      if (item.originPath == 'fullPath') {
        currentPath = decodeURIComponent(route.fullPath);
      } else if (item.originPath == 'path') {
        currentPath = decodeURIComponent(route.path);
      } else if (item.originPath == 'Dontcompilepath') {
        currentPath = route.path;
      }

      // 根据不同的状态处理相应状态码
      if (item.pathList.indexOf(currentPath) != -1 || currentPath === decodeURIComponent(item.pathList)) {
        // 404 页面
        if (item.response == 'NOTFOUND_ERROR_PAGE') {
          res.writeHead(404, {
            'Content-type': 'text/html;charset=utf-8',
            // Location: redirect('/404')
          });
          // res.end();
          res.end(item.errorPageHtml);

        }
        // 对500状态码做的操作
        if (item.response == 'REDREICT' || currentPath === item.pathList) {
          res.writeHead(301, {
            Location: item.redirectMap[currentPath]
          });
          res.end();
        }
        // 改变canonial标签链接
        if (item.response == 'CANONICAL') {
          if (item.redirectMap.hasOwnProperty(currentPath)) {
            isCanonical=true
            let obj = {};
            obj.rel = "canonical";
            obj.href = item.ip + item.redirectMap[currentPath];
            let ary = app.head.link;
            ary.push(obj);
            return;
          }
        }
      }
    }
  );

  // // 改变canonial标签链接
  // let str2 = decodeURIComponent(route.fullPath);
  // if(canonicalLink.hasOwnProperty(str2)){
  //     let obj = {};
  //     obj.rel = "canonical";
  //     obj.href = ip + canonicalLink[str2];
  //     let ary = app.head.link;
  //     ary.push(obj);
  //     return;
  // }
  // let obj = {};
  // obj.rel = "canonical";
  // obj.href = ip + route.path;
  // let ary = app.head.link;
  // ary.push(obj);
  if(!isCanonical){
    let obj = {};
    obj.rel = "canonical";
    obj.href = ip + route.path;
    let ary = app.head.link;
    ary.push(obj);
  }
}
