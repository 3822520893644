const middleware = {}

middleware['addCanonical'] = require('..\\middleware\\addCanonical.js')
middleware['addCanonical'] = middleware['addCanonical'].default || middleware['addCanonical']

middleware['reqdata'] = require('..\\middleware\\reqdata.js')
middleware['reqdata'] = middleware['reqdata'].default || middleware['reqdata']

middleware['seoMiddleware_new'] = require('..\\middleware\\seoMiddleware_new.js')
middleware['seoMiddleware_new'] = middleware['seoMiddleware_new'].default || middleware['seoMiddleware_new']

export default middleware
