<template>
  <header id="header" ref="header">
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      class="active2 active nav-height"
    >
      <div class="top-nav" @click="closeSearch()">
        <!-- logo -->
        <b-navbar-brand href="/" class="brand">
          <img
            style="width: 161px"
            src="@/assets/img/Ruijie Intl Logo svg.svg"
            class="mr-2"
            alt="logo"
          />
        </b-navbar-brand>
        <!-- 切换按钮 -->
        <div class="right-div">
          <!-- nav上边部分 -->
          <div class="right-top">
            <template>
              <!-- 技術&知識下拉PC -->
              <el-dropdown @command="handleLinkCommand">
                <span class="el-dropdown-link">
                  技術&知識<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="(item, index) in techLinkList"
                    :key="index"
                    :command="item.link"
                    >{{ item.title }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
              <div v-for="item of navTopList" :key="item.id">
                <!-- <b-nav-item-dropdown
                  v-if="item.children.length > 0"
                  id="my-nav-dropdown"
                  :text="item.title"
                  toggle-class="nav-link-custom"
                  right

                  >
                  <b-dropdown-item
                    class="top-item"
                    :to="child.link"
                    :active="$route.path === child.link"
                    v-for="child in item.children"
                    :key="child.id"
                    >{{ child.title }}</b-dropdown-item
                  >
                </b-nav-item-dropdown> -->
                <b-nav-item
                  :to="item.link"
                  :active="$route.path === item.link"
                  class="top-item"
                >
                  <img :src="item.imgUrl" class="img" />
                  <span>{{ item.title }}</span>
                </b-nav-item>
              </div>
            </template>
          </div>

          <!-- nav下边部分 -->
          <div class="navcollapse">
            <template>
              <div
                v-for="(item, index) in navBarList"
                :Key="index"
                :class="index != 2 && index != 3 ? 'navitem' : ''"
              >
                <b-nav-item
                  :active="$route.path === item.link"
                  class="no-apse"
                  :href="index == 0 ? 'javascript:void(0)' : item.link"
                  @click="handleNavbarClick(item, '', $event)"
                >
                  {{ item.nav }}
                </b-nav-item>
                <div class="tip-menu-mask"></div>
                <!-- 产品 -->
                <div
                  data-index="0"
                  class="mega-menu"
                  style="min-height: 305px"
                  v-if="index == 0"
                >
                  <div data-v-22190f14="" class="content container">
                    <div data-v-22190f14="" class="w-100">
                      <div
                        class="product-div"
                        v-for="(item, index) in classfyList"
                        :key="item.id"
                      >
                        <b-link class="product-name" :href="item.toLink">{{
                          item.name
                        }}</b-link>
                        <div>
                          <b-link
                            v-for="item1 in item.child"
                            :key="item1.id"
                            class="sub-product-name"
                            :to="{
                              name: `products-${
                                ['switches', 'wireless', 'routers'][index]
                              }-urlName`,
                              params: {
                                urlName: `${item1.urlName}_${item1.id}`,
                                name: item1.name,
                                id: item1.id,
                              },
                            }"
                            @click="jumpProduct(item, item1)"
                          >
                            {{ item1.name }}
                          </b-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 解决方案 -->
                <div
                  data-index="1"
                  class="mega-menu"
                  style="min-height: 200px"
                  v-if="index == 1"
                >
                  <div data-v-22190f14="" class="content container">
                    <div data-v-22190f14="" class="w-100">
                      <div class="product-div">
                        <b-link
                          class="sub-product-name"
                          href="/solutions/litepon"
                        >
                          LitePon集合住宅に向けるオール光ネットワークソリューション
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link
                          class="sub-product-name"
                          :href="'/solutions/ghn'"
                        >
                          G.hn既設電話配線を利用した高速ソリューション
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link
                          class="sub-product-name"
                          :href="'/solutions/japan-cloud-system-solution_431383103847006208.html'"
                        >
                          JaCS(Japan Cloud System)
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link
                          class="sub-product-name"
                          :href="'/solutions/100g-400g-ena-data-center-network-solution_431384761587204096.html'"
                        >
                          100G/400G データセンター
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link
                          class="sub-product-name"
                          :href="'/solutions/mesh-network-solution_432810354147328000.html'"
                        >
                          メッシュネットワーク
                        </b-link>
                      </div>
<!--                      <div class="product-div">-->
<!--                        <b-link-->
<!--                          class="sub-product-name"-->
<!--                          :to="'/solutions/configuration-tool-solution_432810359304749056.html'"-->
<!--                        >-->
<!--                          設定ツール-->
<!--                        </b-link>-->
<!--                      </div>-->
                    </div>
                  </div>
                </div>

                <!-- 企业情报 -->
                <div
                  data-index="4"
                  class="mega-menu"
                  style="min-height: 200px"
                  v-if="index == 4"
                >
                  <div data-v-22190f14="" class="content container">
                    <div data-v-22190f14="" class="w-100">
                      <div class="product-div">
                        <b-link
                          class="sub-product-name"
                          href="/company/profile"
                        >
                          会社概要
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link class="sub-product-name" href="/company/leader">
                          トップメッセージ
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link class="sub-product-name" href="/about-us">
                          Ruijie Networksについて
                        </b-link>
                      </div>
                      <div class="product-div">
                        <b-link class="sub-product-name" href="/careers">
                          採用情報
                        </b-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="search-div" @click.stop="showSearch()">
              <img src="@/static/img/home/search-red.png" class="search-img" />
              サイト内検索
            </div>
          </div>
        </div>
        <!-- 小屏幕显示图屏点击 -->
        <img
          src="~@/static/img/classfy.png"
          class="right-img"
          @click="showRight"
        />
      </div>
    </b-navbar>

    <!--点击搜索 -->
    <div v-show="isShowSearch" class="mask1" @click="closeSearch">
      <div class="search" @click.stop="">
        <div class="searchBox">
          <el-input
            placeholder="モデル名などのキーワードを入力"
            class="search-input"
            v-model="searchValue"
            @keyup.enter.native="toSearchPage"
            @click.stop.native=""
          />
          <img
            class="imgs"
            src="~@/static/img/search.jpeg"
            @click.stop="toSearchPage"
          />
        </div>
        <img
          class="imgs"
          src="~@/static/img/public/close.png"
          style="margin-left: 28px"
          @click.stop="closeSearch"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Breadcrumb from "../breadcrumb/index.vue";
import { productType } from "@/api/api.js";
import { Message } from "element-ui";

import reqApi from "@/api/apiUrl";

export default {
  components: {
    Breadcrumb,
  },
  props: {
    classfyList: {
      type: Array,
    },
  },
  data() {
    return {
      title: "检索结果",
      showCollaps: false,
      showNavbarBg: false,
      showDrop: true,
      // 知识中心跳转链接
      techLinkList: [
        {
          title: "ネットワーク技術",
          // imgUrl: require("~/static/img/public/news-center.png"),
          link: "/technology",
        },
        {
          title: "ブログ",
          // imgUrl: require("~/static/img/public/news-center.png"),
          link: "/blog",
        },
      ],
      navTopList: [
        {
          title: "ニュース",
          imgUrl: require("~/static/img/public/news-center.png"),
          link: "/news",
          children: [],
        },
        {
          title: "ダウンロード",
          imgUrl: require("~/static/img/public/download.png"),
          link: "/downloads",
          children: [],
        },
        {
          title: "お問い合わせ",
          imgUrl: require("~/static/img/public/information.png"),
          link: "/sales",
          children: [],
        },

        {
          title: "パートナー",
          imgUrl: require("~/static/img/public/partner.png"),
          link: "/partners",
          children: [],
        },
      ],
      navBarList: [
        {
          nav: "製品情報",
          link: "/products",
          children: [],
        },
        {
          nav: "ソリューション",
          link: "/solutions",
          children: [],
        },
        {
          nav: "導入事例",
          link: "/case-studies",
          children: [],
        },
        {
          nav: "サポート",
          link: "/service",
          children: [],
        },
        {
          nav: "企業情報",
          link: "/company",
          children: [],
        },
      ],
      isShowFirst: true, //产品中心悬浮
      isShowSearch: false, //搜索
      isShowSm: false, //是否显示小屏幕右侧
      // classfyList: [], //分类数据
      searchValue: "",
    };
  },

  watch: {
    isShowSmallNav(newVal, oldVal) {
      this.isShowSm = newVal;
    },
    screenWidth(val) {
      // 监听屏幕大小
      if (val > 992) {
        this.isShowSm = false;
        this.$store.commit("setIsShowSmallNav", this.isShowSm);
      } else {
        // this.isShowSm = true;
        // this.$store.commit("setIsShowSmallNav", this.isShowSm);
      }
    },
  },
  computed: {
    ...mapMutations([
      "setIsShowSmallNav",
      "setScreenWidth",
      "setSubId",
      "setSubId1",
      "setSubId2",
    ]),
    ...mapState(["isShowSmallNav", "screenWidth", "subId", "subId1", "subId2"]),
  },
  mounted() {
    this.$nextTick(() => {
      this.getHeaderHeight();
    });
    window.addEventListener(
      "scroll",
      this.throttle(this.handleWindowScroll),
      500
    );
    this.$store.commit("setIsShowSmallNav", this.isShowSm);
    const that = this;
    window.onresize = () => {
      return (() => {
        store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
    // this.getProductType();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleWindowScroll);
  },

  methods: {
    jumpProduct(item, item1) {
      console.log("jumpProduct", item, item1);
      this.$store.commit("setCateId", item.id);
      this.$store.commit("setSubId", item1.id);
    },
    jumpProduct1(item, item1) {
      console.log("jumpProduct1", item, item1);
      this.$store.commit("setCateId", item.id);
      this.$store.commit("setSubId1", item1.id);
    },
    jumpProduct2(item, item1) {
      console.log("jumpProduct2", item, item1);
      this.$store.commit("setCateId", item.id);
      this.$store.commit("setSubId2", item1.id);
    },
    handleLinkCommand(link) {
      this.$router.push(link);
    },
    // 导航栏点击事件
    handleNavbarClick(item, index, bvEvt) {
      this.isShowFirst = false;
      this.isShowSearch = false;
      if (index !== "") {
        this.$store.commit("setSubNavIndex", index + 1);
      } else {
        this.$store.commit("setSubNavIndex", 0);
        this.showDrop = false;
      }
      if (index != 0) {
        this.$router.push(item.link);
      }

      this.showCollaps = false;
    },

    // 监听窗口滚动，改变导航背景色
    handleWindowScroll(e) {
      const myTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const flag = myTop > 60;
      if (this.showNavbarBg != flag) {
        this.showNavbarBg = flag;
      }
    },

    throttle(fn, delay = 500) {
      // 设置变量默认为true
      let flag = true; // 为了保证this指向，返回一个箭头函数
      return (...args) => {
        // 判断如果已经在执行就直接return
        if (!flag) return; // 否则就是没有执行，将状态赋值为false
        flag = false; // 设置定时器，设置時間
        setTimeout(() => {
          // 调用apply方法确保this指向问题
          fn.apply(this, args); // 最后将状态重新更改为true，以便程序下次执行
          flag = true;
        }, delay);
      };
    },
    // 获取导航栏高度
    getHeaderHeight() {
      const headerHeight = this.$refs.header.clientHeight;
      this.$store.commit("setHeaderHeight", headerHeight);
    },

    //显示搜索
    showSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.isShowFirst = false;
    },
    //小屏幕显示
    showRight() {
      this.isShowSm = !this.isShowSm;
      this.$store.commit("setIsShowSmallNav", this.isShowSm);
    },
    toSearchPage() {
      if (!this.searchValue) {
        Message({
          type: "warning",
          message: "モデル名などのキーワードを入力",
          duration: 2000,
        });
        return;
      }
      this.isShowSearch = false;
      this.$store.commit("setSearchValue", this.searchValue);
      this.$router.push({
        path: "/search",
        query: {
          value: this.searchValue,
        },
      });
    },
    closeSearch() {
      this.isShowSearch = false;
      this.searchValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
#header:has(.navitem:hover) {
  z-index: 100;
}

a {
  display: flex;
  align-items: center;
  color: #292929 !important;
  font-size: 16px !important;
}
.imgs:hover {
  cursor: pointer;
}
.nav-height {
  height: 99px;
  border-bottom: 1px solid #e6e6e6;
}
.tip-menu-mask {
  position: fixed;
  left: 0;
  top: 99px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: none;
  pointer-events: none;
}
.mega-menu {
  position: absolute;
  left: 0;
  width: 100%;
  top: 99px;
  opacity: 0;
  z-index: 999;
  visibility: hidden;
  transition: opacity 0.8s ease;
  -moz-transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  border-top: 1px solid #d7d7d7;
  background-color: #fff !important;
}
.navitem:hover {
  .tip-menu-mask {
    display: flex !important;
  }
  .mega-menu {
    opacity: 1 !important;
    visibility: visible !important;
  }
}
.search-input {
  height: 48px;
  width: 100%;
  border: none;
}
::v-deep .el-input__inner {
  height: 48px !important;
  border: none;
}
.w-100 {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  flex-wrap: wrap;
  .product-div {
    margin-top: 20px;
    padding: 0 10px;
    height: 33px;
    font-family: Source Han Sans JP;
    font-weight: 500;

    .product-name {
      color: #160000 !important;
      font-size: 24px !important;
      &:hover {
        color: #e60033 !important;
        cursor: pointer;
      }
    }
    .sub-product-name {
      font-size: 16px;
      color: #7d7d7d;
      letter-spacing: 0;
      line-height: 40px;
      white-space: nowrap;
      &:hover {
        color: #e60033 !important;
        cursor: pointer;
      }
    }
  }
}

.top-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 23px;
}

::v-deep.brand {
  padding: 0;
  margin-right: 0;
}

::v-deep.right-div {
  height: 100%;

  .right-top {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    .nav-link {
      padding: 0 0 0 14px;
      display: flex;
      align-items: center;
      color: $nav-defalut-color !important;
      &:hover {
        color: $red-color;
      }
    }

    .top-item {
      font-size: 14px !important;

      letter-spacing: 0;
      line-height: 20px !important;
      height: 20px !important;
      font-family: Source Han Sans JP;
      font-weight: 400;

      .img {
        height: 17px;
        width: 17px;
      }

      span {
        display: inline-block;
        height: 20px !important;
        padding-left: 3px;
        color: #7d7d7d;
        &:hover {
          color: #395a84;
        }
      }
    }
    .el-dropdown{
      line-height: 1;
      font-size: 16px;
    }
  }
}

.right-img {
  height: 30px;
  width: 30px;
  display: none;
}

#header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
}

::v-deep.dropdown:hover .btn,
::v-deep.dropdown-item:hover {
  border-color: transparent;
  background: none;
  transition: all 0.3s;
}

::v-deep.nav-link:hover,
::v-deep.dropdown-item:hover,
::v-deep .navbar-nav .nav-link.active {
  color: $theme-color !important;
  transition: all 0.3s;
}

::v-deep.navbar-toggler {
  padding: 0;
  border: none;
  outline: none;

  .navbar-toggler-icon {
    position: relative;
    width: 1em;
    // height: 0.1em;
    height: 2px;
    border-radius: 1px;
    background: #fff;

    &:after,
    &:before {
      position: absolute;
      left: 0;
      content: "";
      width: 1em;
      height: 2px;
      // height: 0.1rem;
      background: #fff;
      transition: all 0.2s ease-in-out;
    }

    &:before {
      top: -0.3em;
    }

    &:after {
      top: 0.3em;
    }
  }

  &.active .navbar-toggler-icon {
    background: transparent;

    &:before {
      transform: rotate(45deg);
      transform-origin: 8%;
    }

    &:after {
      transform: rotate(-45deg);
      transform-origin: 8%;
    }
  }
}

.dropdown-toggle .active {
  color: $theme-color;
}

::v-deep .nav-link {
  color: #fff !important;
}

::v-deep .navbar {
  background: transparent !important;
  padding: 0;

  &.active {
    background: #fff !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

    .navbar-brand,
    .nav-link {
      &.active {
        color: #395a84 !important;
      }
    }

    .navbar-toggler {
      .navbar-toggler-icon {
        background: #333;

        &:after,
        &:before {
          background: #333;
        }
      }

      &.active .navbar-toggler-icon {
        background: transparent;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  ::v-deep .navbar {
    background: #fff !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

    &.active2 {
      .navbar-brand,
      .nav-link {
        &.active {
          color: #395a84 !important;
        }
      }

      .navbar-toggler {
        .navbar-toggler-icon {
          background: #333;

          &:after,
          &:before {
            background: #333;
          }
        }

        &.active .navbar-toggler-icon {
          background: transparent;
        }
      }
    }
  }

  .right-div {
    display: none;
  }

  .right-img {
    display: flex;
  }
}

@media screen and (min-width: 992px) {
  ::v-deep.dropdown:hover .dropdown-menu {
    display: block;
    transition: all 0.3s;
  }

  ::v-deep .dropdown-menu {
    display: none;
    margin-top: 20px;
    border-color: transparent;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);

    &:after {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 20px;
      background: transparent;
    }

    &:before {
      content: "";
      position: absolute;
      top: -13px;
      right: 1px;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-bottom-color: #fff;
    }
  }
}

::v-deep.navcollapse {
  margin-top: 21px;
  display: flex;
  justify-content: flex-end;
  height: 42px;
  .navitem {
  }
  .no-apse {
    line-height: 42px;
    height: 42px !important;

    .nav-link {
      padding: 0 0 0 51px !important;
      display: flex;
      align-items: center;
      color: $nav-black-color !important;
      &:hover {
        text-decoration: underline !important;
        color: #e60033 !important;
      }
      &.active {
        color: #e60033 !important;
        text-decoration: underline;
        text-decoration-color: #e60033;
      }
    }
  }
}

::v-deep.search-div {
  width: 114px;
  height: 29px;
  margin-left: 37px;
  border: 1px solid #e60033;
  border-radius: 4px;
  background: #fff;
  line-height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #e60033;

  .search-img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  &:hover {
    cursor: pointer;
  }
}

.searchBox {
  width: 42.6%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding-right: 1.61%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search {
  width: 100%;
  height: 148px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox span {
  font-size: 14px;
  color: #b8b8b8;
}

.searchBox img {
  width: 14px;
  height: 14px;
}
.mask1 {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.53) !important;
  z-index: 999;
}

@media (max-width: 750px) {
  .nav-height {
    height: 70px;
  }
}
</style>
