import service from '@/plugins/service';

//招聘列表
export const getJobListings = (data) => {
  return service({
    url: '/api/recruit/pageList',
    method: 'GET',
    params: data,
  });
};

//招聘列表详情
export const getJobListingsDetail = (data) => {
  return service({
    url: '/api/recruit/info/' + data.id,
    method: 'GET',
  });
};

//解决方案列表
export const solutionList = (query) => {
  return service({
    url: '/api/solution/pageList',
    method: 'GET',
    params: query,
  });
};

//技术列表
export const technologyList = (data) => {
  return service({
    url: '/api/topic/pageList',
    method: 'GET',
  });
};
// 技术详情
export const technologyInfo = (data) => {
  return service({
    url: '/api/topic/info/' + data.id,
    method: 'GET',
  });
};


//企业概要/领导寄语
export const companyCulture = (data) => {
  return service({
    url: '/api/company/info',
    method: 'GET',
  });
};

//企业发展历史
export const companyHistory = (data) => {
  return service({
    url: '/api/company/history/' + data.type,
    method: 'GET',
  });
};

//企业wen文化
export const getCulture = (data) => {
  return service({
    url: '/api/company/culture',
    method: 'GET',
  });
};

//企业创新
export const companyInnovate = (data) => {
  return service({
    url: '/api/company/innovate/' + data.type,
    method: 'GET',
  });
};

//企业荣耀
export const companyHonor = (data) => {
  return service({
    url: '/api/company/honor',
    method: 'GET',
  });
};

// 服务与支持
export const serviceSupport = (data) => {
  return service({
    url: '/api/service/info/' + data.type,
    method: 'GET',
  });
};

// 产品分类
export const productType = () => {
  return service({
    url: '/api/goods/category',
    method: 'GET',
  });
};

// 产品列表
export const productList = (query) => {
  return service({
    url: '/api/goods/list',
    method: 'GET',
    params: query,
  });
};

// 锐捷优越性
export const ruijieAdvantage = (query) => {
  return service({
    url: '/api/company/superiority',
    method: 'GET',
    params: query,
  });
};
//优越性详情

export const ruijieAdvantageInfo = (query) => {
  return service({
    url: '/api/company/superiorityInfo/' + query.id,
    method: 'GET',
  });
};

//企业合作伙伴
export const ruijiePartner = (data) => {
  return service({
    url: '/api/company/partner',
    method: 'GET',
    params: data,
  });
};

//产品详情
export const basicInformation = (data) => {
  return service({
    url: '/api/goods/detail/' + data.id,
    method: 'GET',
  });
};

//产品文档
export const productDocumentation = (data) => {
  return service({
    url: '/api/goods/dataDownload',
    method: 'GET',
    params: data,
  });
};

//产品文档分类
export const productDocumenType = (query) => {
  return service({
    url: '/api/goods/categoryList/' + query.id,
    method: 'GET',
  });
};

//资料下载
export const download = (query) => {
  return service({
    url: '/api/goods/fileList',
    method: 'GET',
    params: query,
  });
};

//产品详情描述
export const detailedDescription = (data) => {
  return service({
    url: '/api/goods/desc/' + data.id,
    method: 'GET',
  });
};

//商品详情
export const productDetails = (data) => {
  return service({
    url: '/api/goods/imageAndVideo/' + data.id,
    method: 'GET',
  });
};

//商品详情技术参数
export const technicalParameter = (data) => {
  return service({
    url: '/api/goods/technicalParam/' + data.id,
    method: 'GET',
  });
};

//单页协议
export const singleType = (data) => {
  return service({
    url: '/api/single/info/' + data.type,
    method: 'GET',
  });
};
//全球活动
export const globalActive = (query) => {
  return service({
    url: '/api/company/global',
    method: 'GET',
    params: query,
  });
};

//资料下载
export const getFiles = () => {
  return service({
    url: '/api/goods/fileList',
    method: 'GET',
  });
};

//日本官网案例列表
export const caseList = (query) => {
  return service({
    url: '/api/search/getJapanCaseList',
    method: 'GET',
    params: query,
  });
};

//日本官网新闻列表
export const newsList = (query) => {
  return service({
    url: '/api/search/getJapanNewsList',
    method: 'GET',
    params: query,
  });
};

//日本官网商品列表
export const productsList = (query) => {
  return service({
    url: '/api/search/getJapanSkuList',
    method: 'GET',
    params: query,
  });
};

//日本官网解决方案列表
export const solutionsList = (query) => {
  return service({
    url: '/api/search/getJapanSolutionList',
    method: 'GET',
    params: query,
  });
};

//日本官网产品sku详情
export const productSkuModel = (query) => {
  return service({
    url: '/api/goods/getGoodsSkuModel/' + query.id,
    method: 'POST',
  });
};
//资料下载左侧
export const getLeftFileList = (query) => {
  return service({
    url: '/api/goods/fileListSku',
    method: 'GET',
    params: query,
  });
};
export const getLeftFileListNew = (query) => {
  return service({
    url: '/api/goods/fileListSkuNew',
    method: 'GET',
    params: query,
  });
};
//资料下载左you侧
export const getLeftRightList = (query) => {
  return service({
    url: '/api/goods/fileListNew',
    method: 'GET',
    params: query,
  });
};

//blog列表数据
export const getBlogPageList = (query) => {
  return service({
    url: '/api/blog/pageList',
    method: 'GET',
    params: query,
  });
};

//faq列表数据
export const getFAQPageList = (query) => {
  return service({
    url: '/api/faq/pageList',
    method: 'GET',
    params: query,
  });
};

//问题点赞
export const thumbAdd = (id) => {
  return service({
    url: '/api/goods/addThumb/' + id,
    method: 'POST',
  });
};

//新的表单提交
export function newFormSubmit(data) {
  return service({
    url: '/api/consult/addSqNew',
    method: 'POST',
    data: data,
  });
}

//新的商品列表数据
export const getGoodListNew = (query) => {
  return service({
    url: '/api/goods/listNew',
    method: 'GET',
    params: query,
  });
};

// 获取验证码
export const getCode = () => {
  return service({
    url: '/api/verify/createCode',
    method: 'GET',
    responseType: 'blob',
  });
};

// 校验验证码
export const checkVerifyCode = (verifyCode) => {
  return service({
    url: '/api/verify/checkVerifyCode',
    method: 'POST',
    params: { verifyCode },
  });
};

// 保修查询
export const queryGuarantee = (secrets) => {
  return service({
    url: '/api/service/queryGuarantee',
    method: 'GET',
    params: { secrets },
  });
};
// 保修查询--报告异常
export const reportException = (data) => {
  return service({
    url: '/api/service/guarantee/submit',
    method: 'POST',
    data,
  });
};
