
import reqApi from "@/api/apiUrl";
export default async function ({
    store,
    $axios
}) {

    await $axios.get(reqApi.getProductType).then(function (res) {
        let arr=[];
        if(res.data.data){
            res.data.data.forEach((item,index)=>{
                if(index==0){
                    item.toLink='/products/switches';
                }else if(index==1){
                    item.toLink='/products/wireless';
                }else if(index==2){
                    item.toLink='/products/routers';
                }
                arr.push(item);
            })

        }
        store.commit("setCateList", arr);
    });
    await $axios
        .get(reqApi.getSolutionList + "?limit=4&page=1")
        .then(function (res) {
            let arr = res.data.data.list;
            if(arr.length>0){//解决方案url问题
                arr.forEach((item,index)=>{
                    var isPhone = /mobile/i.test(res.config.headers['user-agent']);
                    let width;
                    if(isPhone){//为了解决seo不让js的问题
                        width = 749;
                    }else{
                        width = 1400;
                    }
                    store.commit("setScreenWidth", width);

                    if(index==0){
                      item['jumpUrlName']='/solutions/japan-cloud-system-solution_431383103847006208.html';
                    }else if(index == 1){
                       item['jumpUrlName']='/solutions/100g-400g-ena-data-center-network-solution_431384761587204096.html';
                    }else if(index == 2){
                       item['jumpUrlName']='/solutions/mesh-network-solution_432810354147328000.html';
                    }else if(index == 3){
                       item['jumpUrlName']='/solutions/configuration-tool-solution_432810359304749056.html';
                    }else{
                        item['jumpUrlName'] = '/solutions/_'+item.id+'.html'
                    }
                })

            }
            store.commit("setSoluList", arr);
        })

}

