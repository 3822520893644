export const state = () => ({
  headerHeight: 0,
  subNavIndex: 0,
  isShowSmallNav:false,
  cateId:'',//产品分类id
  subId:'',//子类id
  subId1:'',//子类id
  subId2:'',//子类id
  searchValue:'',//搜索值
  chooseId:'',//选择的id
  cateList:[],//产品分类列表
  soluList:[],//方案列表
  screenWidth:0//屏幕宽度
})


export const mutations = {
  setHeaderHeight(state, height) {
    state.headerHeight = height
  },
  setSubNavIndex(state, index) {
    state.subNavIndex = index
  },
  setIsShowSmallNav(state, index) {
    state.isShowSmallNav = index
  },
  setCateId(state, index) {
    state.cateId = index
  },
  setSubId(state, index) {
    state.subId = index
  },
  setSubId1(state, index) {
    state.subId1 = index
  },
  setSubId2(state, index) {
    state.subId2 = index
  },
  setSearchValue(state, index){
    state.searchValue = index
  },
  setChooseId(state, index){
    state.chooseId = index
  },
  setCateList (state,index){
    state.cateList = index;
  },
  setSoluList(state,index){
    state.soluList = index;
  },
  setScreenWidth(state,index){
    state.screenWidth = index;
  }
}

