<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!-- <el-breadcrumb-item :to="{ path: '/' }">HOME</el-breadcrumb-item> -->
      <el-breadcrumb-item><a href="https://www.ruijie.co.jp/" style="color:#7d7d7d; font-weight:400">HOME</a></el-breadcrumb-item>
      <el-breadcrumb-item> {{ name }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props:{
    name: {
      type: String,
      default: ''
    },
  },
  data() {
    return {

    };
  },
  mounted() {

  },

  methods: {


  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
  .container{
    padding: 27px  0;
    .el-breadcrumb__item {
      font-size: 12px;
    }

  }
::v-deep .el-breadcrumb__inner{
  color: $nav-defalut-color !important;
}
::v-deep .el-breadcrumb__inner.is-link{
  font-weight: 400 !important;
  color: $nav-defalut-color !important;
}

@media (max-width: 750px){
  .container{
    padding-left:  15px !important;
  }
 
}
</style>
