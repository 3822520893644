<template>
  <div>
    <my-header :classfyList="cateList" />
    <Nuxt keep-alive />
    <my-footer :classfyList="cateList" :solutionList="soluList" />
    <!-- 小屏幕显示图屏点击 -->
    <!-- <div
      class="hidden_view"
      :style="{ height: win_height + 'px' }"
      v-show="isShow"
      @click="hideModal"
    ></div> -->
    <transition name="show_view">
      <div
        class="screen_cent"
        v-if="isShow"
        :style="{ height: win_height + 'px' }"
      >
        <div class="screen_data" transiton="show_view">
          <!-- 顶部 -->
          <div class="scree-top">
            <div class="top-item">
              <el-dropdown @command="handleLinkCommand">
                <span class="el-dropdown-link">
                  技術&知識<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="(item,index) in techLinkList" :key="index" :command="item.link">{{ item.title }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div
              class="top-item"
              v-for="item in navTopList"
              :key="item.id"
              @click="jump(item)"
            >
              <img :src="item.imgUrl" /> <span>{{ item.title }}</span>
            </div>
          </div>
          <!-- 下面 -->
          <div class="screen-bottom">
            <el-collapse style="width: 100%">
              <el-collapse-item title="製品情報">
                <b-link
                  v-for="item1 in cateList"
                  :key="item1.id"
                  class="file-sub"
                  :href="item1.toLink"
                >
                  {{ item1.name }}
                </b-link>
              </el-collapse-item>
            </el-collapse>

            <div
              class="bottom-item"
              v-for="item in navBarList"
              :key="item.id"
              @click="jump(item)"
            >
              <span> {{ item.nav }} </span>
              <i class="el-icon-arrow-right" style="padding-left: 18px"></i>
            </div>

            <div class="searchBox">
              <el-input
                placeholder="モデル名などのキーワードを入力"
                class="search-input"
                v-model="searchValue"
                @keyup.enter.native="toSearchPage"
                @click.stop.native=""
              />
              <img
                class="imgs"
                src="~@/static/img/search.jpeg"
                @click.stop="toSearchPage"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div v-if="showSidenav">
      <!-- pc端显示在右侧 -->
      <div class="l_sidenav on" v-if="screenWidth > 750">
        <ul class="l_sideul">
          <li class="l_sideli l_sideli1">
            <a class="slide-a" href="/sales">
              <img src="@/static/img/mail.png" style="width:20px;height:20px;margin-bottom: 5px;"/>
              <p>お問い合わせ</p>
            </a>
          </li>
          <li class="l_sideli l_sideli2">
            <a class="slide-a" href="/catalogform">
              <img src="@/static/img/book.png" style="width:20px;height:20px;margin-bottom: 5px;"/>
              <!-- <p>カタログダウンロード</p> -->
              <p>カタログ</p>
            </a>
          </li>
        </ul>
      </div>
      <!-- 移动端 -->
      <div class="l_footernav" v-else>
        <a class="left-nav1" href="/sales">
          <img src="@/static/img/mail.png" style="width:20px;height:20px;margin-right: 5px;"/>
          <p>お問い合わせ</p>
        </a>
        <a class="left-nav1" href="/catalogform" style="border-left: 1px solid #f5f5f5;">
          <img src="@/static/img/book.png" style="width:20px;height:20px;margin-right: 5px;"/>
          <!-- <p>カタログダウンロード</p> -->
          <p>カタログ</p>
        </a>
      </div>
    </div>

    <!-- <el-dialog
      custom-class="dialog_pages"
      :visible.sync="dialogTableVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false">
      <div class="dialog_pages-content">
        <i class="el-icon-close" @click="closeDialog" />
        <div class="">
          <div class="left">
            <div class="txt">製品・ソリューションに関するお問い合わせ、お気軽にご相談ください。</div>
            <div class="rj-btn-list">
              <b-link class="rj-btn rj-btn-a" href="/sales" target="_blank">ご相談・お問い合わせ</b-link>
              <span @click="closeDialog">閉じる</span>
            </div>
          </div>
          <div class="right">
            <img src="@/static/img/home/dialog-pages-rw.webp" alt="">
          </div>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import MyHeader from "@/components/header/index";
import Carousel from "@/components/index/carousel";
import MyFooter from "@/components/footer/index";
import { productType } from "@/api/api.js";
if (process.browser) {
  // 在这里根据环境引入wow.js
  var { WOW } = require("wowjs");
}
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MyHeader,
    Carousel,
    MyFooter,
  },
  middleware: "reqdata",
  data() {
    return {
      win_height: "",
      isShow: false,
      // 知识中心跳转链接
      techLinkList:[
        {
            title: "ネットワーク技術",
            // imgUrl: require("~/static/img/public/news-center.png"),
            link: "/technology",
        },
        {
            title: "ブログ",
            // imgUrl: require("~/static/img/public/news-center.png"),
            link: "/blog",
        },
      ],
      navTopList: [
        {
          title: "ニュース",
          imgUrl: require("~/static/img/public/news-center.png"),
          link: "/news",
          children: [],
        },
        {
          title: "ダウンロード",
          imgUrl: require("~/static/img/public/download.png"),
          link: "/downloads",
          children: [],
        },
        {
          title: "お問い合わせ",
          imgUrl: require("~/static/img/public/information.png"),
          link: "/sales",
          children: [],
        },
        {
          title: "採用情報",
          imgUrl: require("~/static/img/public/recirt-info.png"),
          link: "/careers",
          children: [],
        },
        {
          title: "パートナー",
          imgUrl: require("~/static/img/public/partner.png"),
          link: "/partners",
          children: [],
        },
      ],
      navBarList: [
        // { nav: "製品情報", link: "/products", children: [] },
        {
          nav: "ソリューション",
          link: "/solutions",
          children: [],
        },
        {
          nav: "導入事例",
          link: "/case-studies",
          children: [],
        },
        {
          nav: "サポート",
          link: "/service",
          children: [],
        },
        {
          nav: "企業情報",
          link: "/company",
          children: [],
        },
      ],
      searchValue: "",
      showSidenav: false,
      dialogTableVisible: false,
      showDialogPages: ["/products", "/solutions", "/case-studies", "/company", "/about-us", "/careers"],
      isFirstClick: 0,
    };
  },
  watch: {
    isShowSmallNav(newVal, oldVal) {
      this.isShow = newVal;
      if (this.isShow) {
        // this.getProductType();
      }
    },
  },
  computed: {
    ...mapMutations([
      "setIsShowSmallNav",
      "setCateList",
      "setSoluList",
      "setScreenWidth",
    ]),
    ...mapState(["isShowSmallNav", "cateList", "soluList", "screenWidth"]),
  },

  mounted() {
    if (process.browser) {
      // 在页面mounted生命周期里面 根据环境实例化WOW
      new WOW({}).init();
    }
    // 生命周期
    this.win_height = window.innerHeight;
    this.isShow = this.isShowSmallNav;
    this.showSidenav = window.location.href.indexOf("/service") <= -1;
    if(+sessionStorage.getItem("isFirstClick") < 1){
      this.showDialogPages.forEach(item => {
        if(window.location.href.indexOf(item) > -1 || window.location.pathname === '/'){
          let timer = null;
          clearTimeout(timer);
          timer= setTimeout(() => {
            this.dialogTableVisible = true;
          }, 30000);
        }
      })
    }
  },

  methods: {
    // 点击下拉菜单跳转
    handleLinkCommand(link){
      this.$router.push(link);
    },
    closeDialog() {
      this.dialogTableVisible = false;
      this.isFirstClick++
      sessionStorage.setItem("isFirstClick", this.isFirstClick);
    },
    //隐藏
    hideModal() {
      this.isShow = false;
      this.$store.commit("setIsShowSmallNav", false);
    },
    jump(item) {
      this.isShow = false;
      this.$store.commit("setIsShowSmallNav", false);
      this.$router.push({
        path: item.link,
      });
    },
    //跳转搜索
    toSearchPage() {
      // this.$router.push("/search");
      if (!this.searchValue) {
        Message({
          type: "warning",
          message: "モデル名などのキーワードを入力",
          duration: 2000,
        });
        return;
      }
      this.isShowSearch = false;
      this.$store.commit("setSearchValue", this.searchValue);
      this.isShow = false;
      this.$store.commit("setIsShowSmallNav", false);
      this.$router.push({
        path: "/search",
        query: {
          value: this.searchValue,
        },
      });
    },
  },
};
</script>

<style lang="scss">
  .dialog_pages{
    width: 677px;
    margin-top: calc(50vh - 170px)!important;
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0;
    }
    .dialog_pages-content{
      position: relative;
      padding: 0 60px;
      color: #202124;
      .el-icon-close{
        position: absolute;
        top: 30px;
        right: 30px;
        font-size: 26px;
        color: #000;
        cursor: pointer;
      }
      > div{
        display: flex;
        align-items: center;
        .left{
          flex: 1;
          .txt{
            font-size: 20px;
            font-weight: bold;
            line-height: 40px;
            margin-bottom: 30px;
          }
          .rj-btn-list{
            .rj-btn{
              font-size: 16px;
            }
            > span{
              cursor: pointer;
              line-height: 44px;
            }
          }
        }
        .right{
          width : 260px;
          height : 340px;
          > img{
            width: 100%;
            height: 100%;
            object-fit: scale-down;
            image-rendering: -webkit-optimize-contrast;
            image-rendering: crisp-edges;
          }
        }
      }
    }
  }
  @media screen and (max-width: 750px) {
    .dialog_pages{
      width: 100%;
      margin-top: 15vh!important;
      .dialog_pages-content{
        padding: 30px;
        > div{
          flex-wrap: wrap;
          flex-direction: column-reverse;
        }
        .rj-btn-list{
          justify-content: center;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
@import "@/assets/styles/normalize.css";
@import "@/assets/styles/reset.scss";
@import "@/assets/styles/variables.scss";

html {
  font-family: "Segoe UI", "Lucida Grande", Helvetica, Arial, "Microsoft YaHei",
    FreeSans, Arimo, "Droid Sans", "wenquanyi micro hei", "Hiragino Sans GB",
    "Hiragino Sans GB W3", Roboto, Arial, sans-serif;
  font-size: 14px;
  color: #333;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  overflow-x: hidden;
}
a {
  /* color: #007bff; */
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $nav-black-color;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
}
*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
}
.hidden_view {
  width: 100%;
  background: #000000;
  opacity: 0.7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
}
::v-deep .el-input__inner {
  height: 48px !important;
  border: none;
}

.screen_cent {
  // width: 320px;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 70px;
  z-index: 20;
  border-radius: 0;
  overflow: hidden;
  color: $nav-black-color;
}
::v-deep .el-collapse-item__header {
  padding: 0 15px !important;
  border-bottom: 1px solid #e0e0e0;
  color: $nav-black-color;
  font-size: 16px;
}
::v-deep .el-collapse-item__arrow {
  margin: 0 0 0 auto;
}
::v-deep .el-collapse-item__content {
  padding-bottom: 0;
  .file-sub {
    height: 40px !important;
    line-height: 40px;
    padding-left: 30px;
  }
}

.screen_data {
  width: 100%;
  height: 100%;
  background: #ffffff;
  .scree-top {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    .top-item {
      width: 50%;
      border: 1px solid $border-color;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px;
      img {
        width: 17px;
        height: 17px;
        margin-right: 3px;
      }
      .el-dropdown{
        font-size: 16px;
      }
    }
  }
  .screen-bottom {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .bottom-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid $border-color;
      width: 100%;
    }
    .search-div {
      width: 220px;
      height: 29px;
      margin-left: 37px;
      border: 1px solid #e60033;
      border-radius: 4px;
      background: #fff;
      line-height: 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      color: #e60033;
      margin: 15px 0;
      .search-img {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
}

.searchBox {
  width: 80%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding-right: 1.61%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.search {
  width: 100%;
  height: 148px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.searchBox span {
  font-size: 14px;
  color: #b8b8b8;
}

.searchBox img {
  width: 14px;
  height: 14px;
}
.l_sidenav {
  position: fixed;
  top: 60%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 900;
  transition: all 0.36s;
  background:  $red-color;
  color: white;
}
.slide-a {
  color: white;
  /* writing-mode: vertical-rl; */
  writing-mode: tb;
  width: 64px;
  cursor: pointer;
  padding: 30px 20px;
  font-family: Source Han Sans JP;
  font-size: 16px;
  height: 185px;
  justify-content: center !important;
  &:hover {
    opacity: 0.8;
  }
}


.l_sideul {
  // border-top: 3px solid #fff;
  // border-left: 3px solid #fff;
  // box-shadow: -3px -3px 0px 0px #fff;
}
.l_sideli2 {
  border-top: 1px solid #f5f5f5;
}
.l_footernav {
  height: 50px;
  width: 100%;
  background: #fff;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
  .left-nav1{
    background: $red-color;
    width: 50% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
  }
}
@media screen and (max-width: 750px) {
  .backtop {
    bottom: 70px !important;
  }
  .text-center {
    margin-bottom: 50px !important;
  }
}
</style>
