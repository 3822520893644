import service from '@/plugins/service'

// 轮播图
export const getBanner = (query) => {
  return service({
    url: '/api/banner/list/' + query.type,
    method: 'GET',
   
  })
}

//产品列表
export const getHotProduct = (query) => {
  return service({
    url: '/api/goods/index',
    method: 'GET',
    
  })
}

//聚合标签推荐产品列表
export const getTagsHotProduct = (query) => {
  return service({
    url: '/api/goods/queryTop',
    method: 'GET',
    params: query
  })
}

//blog faq 推荐产品列表
export const getBlogFAQHotProduct = (query) => {
  return service({
    url: '/api/goods/queryTopByWriting',
    method: 'GET',
    params: query
  })
}
