<template>
  <div style="background-color: rgb(246, 246, 246)">
    <div class="container hotProduct" >
      <b-row class="mx-0">
        <b-col
          lg="3"
          md="6"
          sm="12"
          v-for="(item,index) of dataList"
          :key="index"
          class="wow"
        >
        <!-- <b-link :href="`/products/info?productId=`+item.id + `&from=1`"> -->
        <!-- <b-link :to="{name:'products-urlName', params:{urlName: `${item.urlName}`+'_p'+`${item.id}.html`,from:1}}"> -->
           <b-link :href="`/products/${item.urlName}_p${item.id}.html`">
           <b-card img-top :class="{'auto-height':!isAutoHeight}">
            <b-card-img
              class="mx-auto d-block rounded thumbnail"
              :src="item.imgUrl || defaultImg"
              :img-alt="`${secondaryTitle}-${item.name}`"
              :title="`${item.name}`"
            >
            </b-card-img>
            <div class="classfy-title diandiandian">
              {{ item.name }}
            </div>
            <div class="classfy-subtitle twodiandiandian" v-if="showRemarks">
              {{ item.remarks }}
            </div>
            <div class="classft-tag" v-if="showTags">
              <span v-for="item1 in item.label" :key="item1">
                {{ item1 }}</span
              >
            </div>
          </b-card>
        </b-link>

        </b-col>
      </b-row>
      <!-- <div class="no-data" v-if="dataList.length==0">
        <img src="@/static/img/public/no-data.png" />
        <span>データなし</span>
      </div> -->
    </div>
  </div>
</template>

<script>
import MySlot from "@/components/slot/index";

export default {
  components: {
    MySlot,
  },
  props: {
    dataList: {
      type: Array,
    },
    secondaryTitle: {
      type: String,

    },
    labelId:{
      type:String,
    },
    isAutoHeight: {
      type: Boolean,
      default: true
    },
    showRemarks: {
      type: Boolean,
      default: true
    },
    showTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      title: "製品カテゴリー",
      defaultImg:require('~/static/img/default.png')
    };
  },
  methods: {
    clickProductItem(item) {
      this.$emit("sendProductFunc", item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
a {
  /* color: #007bff; */
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $nav-black-color;
  &:hover {
    text-decoration: none;
  }
}
.card-body {
  padding: 0;
}
.hotProduct{
  padding-top: 47px;
}
::v-deep .wrapper {
  padding-bottom: 34px !important;
}
/*.wrapper {*/
/*padding: 67px 0 40px 0;*/
/*}*/

.card {
  border: none;
  background: #ffffff;
  margin-bottom: 32px;
  height: 480px;
  width: 100%;
  &.auto-height {
    height: auto;
    .classfy-title{
      margin-bottom: 20px;
    }
  }
  img {
    margin-top: 29px;
    width: 226px !important;
    height: 226px !important;
  }
  //   .mask {
  //     position: absolute;
  //     left: 0;
  //     right: 0;
  //     bottom: 142px;
  //     display: none;
  //     align-items: center;
  //     text-align:left;
  //     background: rgba(#000000,0.13);
  //     padding: 24px 43px;
  //     color: #FFFFFF;
  //     .esc:hover {
  //       color: $red-color !important;
  //       cursor: pointer;
  //       transition: all .3s;
  //     }
  //   }
}
.more {
  background: $red-color;
  border: 1px solid $red-color;

  color: #ffffff !important;
}
.card:hover {
  //   box-shadow: 0 0 20px 5px rgba(0,0,0,0.11);
  //   transition: all .3s;
  //   .mask {
  //     display: flex !important;
  //   }
}
.classfy-title {
  font-size: 18px;
  color: $nav-black-color;
  letter-spacing: 0.45px;
  line-height: 33px;
  height: 33px;
  padding: 0 20px;
  margin-top: 23px;
  &:hover {
    color: $red-color;
    cursor: pointer;
    translate: all 0.3s;
  }
}
.classfy-subtitle {
  height: 50px;
  font-family: Source Han Sans JP;
  font-weight: 400;
  font-size: 14px;
  color: $nav-defalut-color;
  letter-spacing: 0.4px;
  line-height: 25px;
  padding: 0 20px;
  margin-top: 13px;
  text-align: left;
}

.classft-tag {
  padding: 0 20px;
  margin-top: 23px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  span {
    display: inline-block;
    height: 22px;
    //line-height: 22px;
    padding: 0 8px;
    border: 1px solid $nav-defalut-color;
    font-size: 12px;
    color: #7d7d7d;
    margin: 0 8px 8px 0;
  }
}
</style>
