<template>
  <section :class="{bgColor: showBgColor}">
    <div class="container wrapper">
      <h2 class="title text-center wow"
      >{{title}}</h2>
<!--      <div class="title-icon"></div>-->
      <p class="desc mt-3 mb-4 mx-5 text-muted text-center wow "

        v-if="desc"
      >{{desc}}</p>
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    showBgColor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';
.bgColor {
  background: #F1F3F5;
}
.wrapper {
  padding: 67px 0 80px 0;
  .title {
    height: 54px;
    font-family: Source Han Sans JP;
    font-weight: Bold;
    font-size: 30px;
    color: #292929;
    letter-spacing: 0.9px;
    margin-bottom: 54px;
  }
  .title-icon {
    width: 30px;
    height: 5px;
    margin: 0 auto;
    background: $theme-color;
  }
  .desc {
    font-size: 16px;
  }
}
</style>
