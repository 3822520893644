import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2a072e57 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _2bbe04a4 = () => interopDefault(import('..\\pages\\blog\\index.vue' /* webpackChunkName: "pages/blog/index" */))
const _03fb41af = () => interopDefault(import('..\\pages\\careers\\index.vue' /* webpackChunkName: "pages/careers/index" */))
const _43e4f074 = () => interopDefault(import('..\\pages\\case-studies\\index.vue' /* webpackChunkName: "pages/case-studies/index" */))
const _78fcbc07 = () => interopDefault(import('..\\pages\\catalogform\\index.vue' /* webpackChunkName: "pages/catalogform/index" */))
const _62ff7e32 = () => interopDefault(import('..\\pages\\company\\index.vue' /* webpackChunkName: "pages/company/index" */))
const _9d337538 = () => interopDefault(import('..\\pages\\contact\\index.vue' /* webpackChunkName: "pages/contact/index" */))
const _322b8539 = () => interopDefault(import('..\\pages\\downloads\\index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _cb0734a4 = () => interopDefault(import('..\\pages\\faq\\index.vue' /* webpackChunkName: "pages/faq/index" */))
const _602023b2 = () => interopDefault(import('..\\pages\\get-price\\index.vue' /* webpackChunkName: "pages/get-price/index" */))
const _78752c46 = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _275172e0 = () => interopDefault(import('..\\pages\\otherForm\\index.vue' /* webpackChunkName: "pages/otherForm/index" */))
const _07e0de05 = () => interopDefault(import('..\\pages\\partners\\index.vue' /* webpackChunkName: "pages/partners/index" */))
const _14208c29 = () => interopDefault(import('..\\pages\\postSale\\index.vue' /* webpackChunkName: "pages/postSale/index" */))
const _c8b8f0b2 = () => interopDefault(import('..\\pages\\presales\\index.vue' /* webpackChunkName: "pages/presales/index" */))
const _ce8068a8 = () => interopDefault(import('..\\pages\\products\\index.vue' /* webpackChunkName: "pages/products/index" */))
const _5ca3852c = () => interopDefault(import('..\\pages\\rita\\index.vue' /* webpackChunkName: "pages/rita/index" */))
const _3aff164c = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages/search" */))
const _ad34b8a2 = () => interopDefault(import('..\\pages\\service\\index.vue' /* webpackChunkName: "pages/service/index" */))
const _23b128aa = () => interopDefault(import('..\\pages\\solutions\\index.vue' /* webpackChunkName: "pages/solutions/index" */))
const _1ca0318b = () => interopDefault(import('..\\pages\\technology\\index.js' /* webpackChunkName: "pages/technology/index" */))
const _6baf173e = () => interopDefault(import('..\\pages\\careers\\info.vue' /* webpackChunkName: "pages/careers/info" */))
const _580f5aab = () => interopDefault(import('..\\pages\\case-studies\\ghnaiconnectjp\\index.vue' /* webpackChunkName: "pages/case-studies/ghnaiconnectjp/index" */))
const _1d1d2c7a = () => interopDefault(import('..\\pages\\case-studies\\ghnjttjp\\index.vue' /* webpackChunkName: "pages/case-studies/ghnjttjp/index" */))
const _1b8ce12c = () => interopDefault(import('..\\pages\\case-studies\\info.vue' /* webpackChunkName: "pages/case-studies/info" */))
const _a4a85ad0 = () => interopDefault(import('..\\pages\\company\\leaderSmessage.vue' /* webpackChunkName: "pages/company/leaderSmessage" */))
const _28ebb2fe = () => interopDefault(import('..\\pages\\company\\profile.vue' /* webpackChunkName: "pages/company/profile" */))
const _5b828061 = () => interopDefault(import('..\\pages\\events\\interop2023\\index.vue' /* webpackChunkName: "pages/events/interop2023/index" */))
const _ecb843ee = () => interopDefault(import('..\\pages\\news\\newsDetail.vue' /* webpackChunkName: "pages/news/newsDetail" */))
const _3345d9b4 = () => interopDefault(import('..\\pages\\products\\all-products.vue' /* webpackChunkName: "pages/products/all-products" */))
const _6bdc36c4 = () => interopDefault(import('..\\pages\\products\\info.vue' /* webpackChunkName: "pages/products/info" */))
const _76794f1e = () => interopDefault(import('..\\pages\\products\\routers\\index.js' /* webpackChunkName: "pages/products/routers/index" */))
const _2ba76e76 = () => interopDefault(import('..\\pages\\products\\switches\\index.js' /* webpackChunkName: "pages/products/switches/index" */))
const _027ea164 = () => interopDefault(import('..\\pages\\products\\wireless\\index.vue' /* webpackChunkName: "pages/products/wireless/index" */))
const _433c811a = () => interopDefault(import('..\\pages\\public\\protocol.vue' /* webpackChunkName: "pages/public/protocol" */))
const _ba919324 = () => interopDefault(import('..\\pages\\service\\afterSaleFlow.vue' /* webpackChunkName: "pages/service/afterSaleFlow" */))
const _ed1a5d9a = () => interopDefault(import('..\\pages\\service\\faq.vue' /* webpackChunkName: "pages/service/faq" */))
const _7ce9d261 = () => interopDefault(import('..\\pages\\service\\info.vue' /* webpackChunkName: "pages/service/info" */))
const _127d8822 = () => interopDefault(import('..\\pages\\service\\serviceInfo.js' /* webpackChunkName: "pages/service/serviceInfo" */))
const _4069be50 = () => interopDefault(import('..\\pages\\service\\warranty-check\\index.vue' /* webpackChunkName: "pages/service/warranty-check/index" */))
const _75b55d41 = () => interopDefault(import('..\\pages\\service\\warrantyPolicy.vue' /* webpackChunkName: "pages/service/warrantyPolicy" */))
const _6e140357 = () => interopDefault(import('..\\pages\\solutions\\detail0.vue' /* webpackChunkName: "pages/solutions/detail0" */))
const _6e221ad8 = () => interopDefault(import('..\\pages\\solutions\\detail1.vue' /* webpackChunkName: "pages/solutions/detail1" */))
const _6e303259 = () => interopDefault(import('..\\pages\\solutions\\detail2.vue' /* webpackChunkName: "pages/solutions/detail2" */))
const _6e3e49da = () => interopDefault(import('..\\pages\\solutions\\detail3.vue' /* webpackChunkName: "pages/solutions/detail3" */))
const _5513badd = () => interopDefault(import('..\\pages\\solutions\\ghn\\index.vue' /* webpackChunkName: "pages/solutions/ghn/index" */))
const _7a277bf4 = () => interopDefault(import('..\\pages\\solutions\\info.vue' /* webpackChunkName: "pages/solutions/info" */))
const _29e1f1ae = () => interopDefault(import('..\\pages\\solutions\\litepon\\index.vue' /* webpackChunkName: "pages/solutions/litepon/index" */))
const _62cf1d1e = () => interopDefault(import('..\\pages\\service\\warranty-check\\report.vue' /* webpackChunkName: "pages/service/warranty-check/report" */))
const _2d21c308 = () => interopDefault(import('..\\pages\\service\\warranty-check\\results.vue' /* webpackChunkName: "pages/service/warranty-check/results" */))
const _a7055c1a = () => interopDefault(import('..\\pages\\solutions\\japan-cloud-system-solution\\gdpr\\index.vue' /* webpackChunkName: "pages/solutions/japan-cloud-system-solution/gdpr/index" */))
const _01f080b0 = () => interopDefault(import('..\\pages\\products\\routers\\_urlName.js' /* webpackChunkName: "pages/products/routers/_urlName" */))
const _70c7bfdc = () => interopDefault(import('..\\pages\\products\\switches\\_urlName.js' /* webpackChunkName: "pages/products/switches/_urlName" */))
const _6bf3dec0 = () => interopDefault(import('..\\pages\\products\\wireless\\_urlName.js' /* webpackChunkName: "pages/products/wireless/_urlName" */))
const _72fcc6af = () => interopDefault(import('..\\pages\\blog\\_urlName.vue' /* webpackChunkName: "pages/blog/_urlName" */))
const _1c6711e4 = () => interopDefault(import('..\\pages\\careers\\_urlName.vue' /* webpackChunkName: "pages/careers/_urlName" */))
const _2656e597 = () => interopDefault(import('..\\pages\\case-studies\\_urlName.vue' /* webpackChunkName: "pages/case-studies/_urlName" */))
const _50085eaf = () => interopDefault(import('..\\pages\\faq\\_urlName.vue' /* webpackChunkName: "pages/faq/_urlName" */))
const _8ff38fc0 = () => interopDefault(import('..\\pages\\news\\_urlName.vue' /* webpackChunkName: "pages/news/_urlName" */))
const _35424ff1 = () => interopDefault(import('..\\pages\\products\\_urlName.vue' /* webpackChunkName: "pages/products/_urlName" */))
const _10a7a234 = () => interopDefault(import('..\\pages\\tags\\_urlName.vue' /* webpackChunkName: "pages/tags/_urlName" */))
const _a1764d54 = () => interopDefault(import('..\\pages\\technology\\_urlName.js' /* webpackChunkName: "pages/technology/_urlName" */))
const _f43592e0 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2a072e57,
    name: "about"
  }, {
    path: "/blog",
    component: _2bbe04a4,
    name: "blog"
  }, {
    path: "/careers",
    component: _03fb41af,
    name: "careers"
  }, {
    path: "/case-studies",
    component: _43e4f074,
    name: "case-studies"
  }, {
    path: "/catalogform",
    component: _78fcbc07,
    name: "catalogform"
  }, {
    path: "/company",
    component: _62ff7e32,
    name: "company"
  }, {
    path: "/contact",
    component: _9d337538,
    name: "contact"
  }, {
    path: "/downloads",
    component: _322b8539,
    name: "downloads"
  }, {
    path: "/faq",
    component: _cb0734a4,
    name: "faq"
  }, {
    path: "/get-price",
    component: _602023b2,
    name: "get-price"
  }, {
    path: "/news",
    component: _78752c46,
    name: "news"
  }, {
    path: "/otherForm",
    component: _275172e0,
    name: "otherForm"
  }, {
    path: "/partners",
    component: _07e0de05,
    name: "partners"
  }, {
    path: "/postSale",
    component: _14208c29,
    name: "postSale"
  }, {
    path: "/presales",
    component: _c8b8f0b2,
    name: "presales"
  }, {
    path: "/products",
    component: _ce8068a8,
    name: "products"
  }, {
    path: "/rita",
    component: _5ca3852c,
    name: "rita"
  }, {
    path: "/search",
    component: _3aff164c,
    name: "search"
  }, {
    path: "/service",
    component: _ad34b8a2,
    name: "service"
  }, {
    path: "/solutions",
    component: _23b128aa,
    name: "solutions"
  }, {
    path: "/technology",
    component: _1ca0318b,
    name: "technology"
  }, {
    path: "/careers/info",
    component: _6baf173e,
    name: "careers-info"
  }, {
    path: "/case-studies/ghnaiconnectjp",
    component: _580f5aab,
    name: "case-studies-ghnaiconnectjp"
  }, {
    path: "/case-studies/ghnjttjp",
    component: _1d1d2c7a,
    name: "case-studies-ghnjttjp"
  }, {
    path: "/case-studies/info",
    component: _1b8ce12c,
    name: "case-studies-info"
  }, {
    path: "/company/leaderSmessage",
    component: _a4a85ad0,
    name: "company-leaderSmessage"
  }, {
    path: "/company/profile",
    component: _28ebb2fe,
    name: "company-profile"
  }, {
    path: "/events/interop2023",
    component: _5b828061,
    name: "events-interop2023"
  }, {
    path: "/news/newsDetail",
    component: _ecb843ee,
    name: "news-newsDetail"
  }, {
    path: "/products/all-products",
    component: _3345d9b4,
    name: "products-all-products"
  }, {
    path: "/products/info",
    component: _6bdc36c4,
    name: "products-info"
  }, {
    path: "/products/routers",
    component: _76794f1e,
    name: "products-routers"
  }, {
    path: "/products/switches",
    component: _2ba76e76,
    name: "products-switches"
  }, {
    path: "/products/wireless",
    component: _027ea164,
    name: "products-wireless"
  }, {
    path: "/public/protocol",
    component: _433c811a,
    name: "public-protocol"
  }, {
    path: "/service/afterSaleFlow",
    component: _ba919324,
    name: "service-afterSaleFlow"
  }, {
    path: "/service/faq",
    component: _ed1a5d9a,
    name: "service-faq"
  }, {
    path: "/service/info",
    component: _7ce9d261,
    name: "service-info"
  }, {
    path: "/service/serviceInfo",
    component: _127d8822,
    name: "service-serviceInfo"
  }, {
    path: "/service/warranty-check",
    component: _4069be50,
    name: "service-warranty-check"
  }, {
    path: "/service/warrantyPolicy",
    component: _75b55d41,
    name: "service-warrantyPolicy"
  }, {
    path: "/solutions/detail0",
    component: _6e140357,
    name: "solutions-detail0"
  }, {
    path: "/solutions/detail1",
    component: _6e221ad8,
    name: "solutions-detail1"
  }, {
    path: "/solutions/detail2",
    component: _6e303259,
    name: "solutions-detail2"
  }, {
    path: "/solutions/detail3",
    component: _6e3e49da,
    name: "solutions-detail3"
  }, {
    path: "/solutions/ghn",
    component: _5513badd,
    name: "solutions-ghn"
  }, {
    path: "/solutions/info",
    component: _7a277bf4,
    name: "solutions-info"
  }, {
    path: "/solutions/litepon",
    component: _29e1f1ae,
    name: "solutions-litepon"
  }, {
    path: "/service/warranty-check/report",
    component: _62cf1d1e,
    name: "service-warranty-check-report"
  }, {
    path: "/service/warranty-check/results",
    component: _2d21c308,
    name: "service-warranty-check-results"
  }, {
    path: "/solutions/japan-cloud-system-solution/gdpr",
    component: _a7055c1a,
    name: "solutions-japan-cloud-system-solution-gdpr"
  }, {
    path: "/products/routers/:urlName",
    component: _01f080b0,
    name: "products-routers-urlName"
  }, {
    path: "/products/switches/:urlName",
    component: _70c7bfdc,
    name: "products-switches-urlName"
  }, {
    path: "/products/wireless/:urlName",
    component: _6bf3dec0,
    name: "products-wireless-urlName"
  }, {
    path: "/blog/:urlName",
    component: _72fcc6af,
    name: "blog-urlName"
  }, {
    path: "/careers/:urlName",
    component: _1c6711e4,
    name: "careers-urlName"
  }, {
    path: "/case-studies/:urlName?",
    component: _2656e597,
    name: "case-studies-urlName"
  }, {
    path: "/faq/:urlName",
    component: _50085eaf,
    name: "faq-urlName"
  }, {
    path: "/news/:urlName",
    component: _8ff38fc0,
    name: "news-urlName"
  }, {
    path: "/products/:urlName",
    component: _35424ff1,
    name: "products-urlName"
  }, {
    path: "/tags/:urlName?",
    component: _10a7a234,
    name: "tags-urlName"
  }, {
    path: "/technology/:urlName",
    component: _a1764d54,
    name: "technology-urlName"
  }, {
    path: "/",
    component: _f43592e0,
    name: "index"
  }, {
    path: "/service/afterSaleFlow",
    component: _ba919324,
    alias: "/service/aftersale-flow",
    name: "afterSaleFlow"
  }, {
    path: "/service/warrantyPolicy",
    component: _75b55d41,
    alias: "/service/product-warranty-policy",
    name: "warrantyPolicy"
  }, {
    path: "/service/serviceInfo",
    component: _127d8822,
    alias: "/service/service-info",
    name: "serviceInfo"
  }, {
    path: "/company/leaderSmessage",
    component: _a4a85ad0,
    alias: "/company/leader",
    name: "leaderSmessage"
  }, {
    path: "/about",
    component: _2a072e57,
    alias: "/about-us",
    name: "about"
  }, {
    path: "/careers",
    component: _03fb41af,
    alias: "/careers",
    name: "careers"
  }, {
    path: "/public/protocol",
    component: _433c811a,
    alias: "/privacy-policy",
    name: "protocol"
  }, {
    path: "/presales",
    component: _c8b8f0b2,
    alias: "/sales",
    name: "presales"
  }, {
    path: "/postSale",
    component: _14208c29,
    alias: "/service/post-sales",
    name: "postSale"
  }, {
    path: "/otherForm",
    component: _275172e0,
    alias: "/service/request",
    name: "otherForm"
  }, {
    path: "/solutions/detail0",
    component: _6e140357,
    alias: "/solutions/japan-cloud-system-solution_431383103847006208.html",
    name: "detail0"
  }, {
    path: "/solutions/detail1",
    component: _6e221ad8,
    alias: "/solutions/100g-400g-ena-data-center-network-solution_431384761587204096.html",
    name: "detail1"
  }, {
    path: "/solutions/detail2",
    component: _6e303259,
    alias: "/solutions/mesh-network-solution_432810354147328000.html",
    name: "detail2"
  }, {
    path: "/solutions/detail3",
    component: _6e3e49da,
    alias: "/solutions/configuration-tool-solution_432810359304749056.html",
    name: "detail3"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
