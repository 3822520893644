
/**  * 封装Axios  * 处理请求、响应错误信息  */
import { Message } from 'element-ui'
import axios from 'axios' //引用axios  
const service = axios.create({
    baseURL: '/api',
    withCredentials: true,
    timeout: 10000
}) // request interceptor 
service.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json';
    return config
}, error => { 
    return Promise.reject(error)
})


// 响应拦截 对响应消息作初步的处理
service.interceptors.response.use(resp => {
  if (resp.data) {
   
    if (resp.data.code != 200 ) {
      
      Message({
        type: 'error',
        message: resp.data.message,
        duration: 5000
      })
      return
    }else{
      return resp.data
    }
   
  } else {
    return resp
  }
}, error => {
  const { response } = error
  const { status, statusText } = response
  if (process.client) {
    $nuxt.error({ message: statusText, statusCode: status })
        // $nuxt只在客戶端渲染能用
  } else {
        // $nuxt在SSR時undefined
        return Promise.reject(error);
  }
  return Promise.reject(error);
  // if (error.response) {
    
  //   Message({
  //     type: 'error',
  //     message: error.response.data.message,
  //     duration: 5000
  //   })
  // }
})

export default service 
