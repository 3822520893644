let ipaddress = "https://www.ruijie.co.jp";
if (process.env.NODE_ENV == 'development') {
  ipaddress = "http://172.16.50.11:3020";
}
const seoRouteConfig = [{
    response: 'NOTFOUND_ERROR_PAGE',
    originPath: 'fullPath',
    pathList: [
      '/products/wireless/eol_1739105953257807873',
      '/products/info',
      '/products/info/',
      '/products/wireless/',
      '/products/switch/',
      '/products/router',
      '/news/wireless_japan_2021/',
      '/news/wireless_japan_2021',
      '/news/リアル展示会終了しました！多数のご来場ありが/',
      '/news/リアル展示会終了しました！多数のご来場ありが',
      '/news/新聞広告掲載のお知らせ/', '/news/新聞広告掲載のお知らせ',
      '/news/【いよいよ開催！】ワイヤレスジャパン2021展示会/',
      '/news/【いよいよ開催！】ワイヤレスジャパン2021展示会', '/service/info',
      '/news/newsDetail',
      '/solutions/info',
      '/case-studies/info',
      '/careers/info',
      '/products/info?productId=',
      '/news/wi-fi-6対応「rg-ap180ja-jp」%E3%80%80累計販売台数4万台達成について',
      '/news/wi-fi-6%E5%AF%BE%E5%BF%9C%E3%80%8Crg-ap180ja-jp%E3%80%8D%E3%80%80%E7%B4%AF%E8%A8%88%E8%B2%A9%E5%A3%B2%E5%8F%B0%E6%95%B04%E4%B8%87%E5%8F%B0%E9%81%94%E6%88%90%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6'
    ],
    errorPageHtml: `<div class="__nuxt-error-page" style="color: #47494e;display: flex;align-items: center;justify-content: center; height: 780px;">
    <div class="error" style=" max-width: 450px;text-align: center;">
        <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" fill="#DBE1EC" viewBox="0 0 48 48"><path d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"></path></svg>
        <div style="font-size: 24px;font-size: 1.5rem;margin-top: 15px;color: #47494e;margin-bottom: 8px">お探しのページは見つかりませんでした</div>
        <a href="https://www.ruijie.co.jp" style="text-decoration: none;color:#007BFF">ホームページへ戻る</a>
        </div>
    </div>`
  },
  {
    response: 'REDREICT',
    originPath: 'path',
    pathList: [
      '/news/月刊テレコミュニケーション２０２１年１１月号/',
      '/news/月刊テレコミュニケーション２０２１年１１月号',
      '/news/wi-fi-6対応「rg-ap180ja-jp」 累計販売台数4万台達成について',
      '/news/wi-fi-6対応「rg-ap180ja-jp」 累計販売台数4万台達成について/',
      '/news/ruijie-networksが日本のデータセンタースイッチ市場に進出',
      '/news/ruijie-networksが日本のデータセンタースイッチ市場に進出/',
      '/products/',
      '/solutions/',
      '/case-studies/',
      '/news/',
      '/service/',
      '/service/aftersale-flow/',
      '/service/afterSaleFlow',
      '/service/warrantyPolicy',
      '/service/product-warranty-policy/',
      '/service/service-info/',
      '/otherForm',
      '/service/request/',
      '/postSale',
      '/service/post-sales/',
      '/company/',
      '/company/leaderSmessage',
      '/company/leader/',
      '/company/profile/',
      '/about-us/',
      '/about',
      '/about/',
      '/careers/',
      '/downloads/',
      '/privacy-policy/',
      '/public/protocol',
      '/presales',
      '/sales/',
      '/solutions/detail0',
      '/solutions/detail1',
      '/solutions/detail2',
      '/solutions/detail3',
      '/careers/null_r431723517283401728.html',
      '/news/%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9_433712562959548416.html',
      '/news/アクセス_433712562959548416.html',
      '/news/Interop%20Tokyo%202022_433712652887785472.html',
      '/news/Interop Tokyo 2022_433712652887785472.html',
      '/news/Interop-Tokyo-2022_434034990557167616.html',
      '/products/xs-s1930j-8gt2sfp_p432111497256108032.html',

      '/products/info?productId=432110275305668608',
      '/products/info?productId=432111497256108032',
      '/products/info?productId=432111650928590848',
      '/products/info?productId=432585132419842048',
      '/products/info?productId=432585440843792384',
      '/products/info?productId=432585489170300928',
      '/products/info?productId=432585532103983104',
      '/products/info?productId=432585618671534080',
      '/products/info?productId=432585663966609408',
      '/products/switches/controller_432021115505213440',
      '/products/switches/indoor-access-point_431909048971952128',
      '/products/switches/outdoor-access-point_432021108698906624',
      '/products/switches/wall-embedded-access-point_431909054866522112',
      '/products/wireless/data-center-switches_431909019516665856',
      '/products/wireless/enterprise-switches_431909030867501056',
      '/products/null_p432092720590749696.html',
      '/products/null_p432203439985131520.html',
      '/products/null_p432204377660588032.html'
    ],
    redirectMap: {
      '/products/info?productId=432110275305668608':'/products/rg-ap180-ac_p432110275305668608.html',
      '/products/info?productId=432111497256108032':'/products/rg-xs-s1930j-8gt2sfp-p_p432111497256108032.html',
      '/products/info?productId=432111650928590848':'/products/rg-ap180-pe_p432111650928590848.html',
      '/products/info?productId=432585132419842048':'/products/xs-s1930j-8gt2sfp_p432585132419842048.html',
      '/products/info?productId=432585440843792384':'/products/xs-s1930j-18gt2sfp_p432585440843792384.html',
      '/products/info?productId=432585489170300928':'/products/xs-s1930j-18gt2sfp-p_p432585489170300928.html',
      '/products/info?productId=432585532103983104':'/products/xs-s1930j-24gt4sfp-2gt_p432585532103983104.html',
      '/products/info?productId=432585618671534080':'/products/xs-s1930j-24gt4sfp-2gt-p_p432585618671534080.html',
      '/products/info?productId=432585663966609408':'/products/xs-s1930j-48gt4sfp_p432585663966609408.html',
      '/products/switches/controller_432021115505213440':'/products/wireless/controller_432021115505213440',
      '/products/switches/indoor-access-point_431909048971952128':'/products/wireless/indoor-access-point_431909048971952128',
      '/products/switches/outdoor-access-point_432021108698906624':'/products/wireless/outdoor-access-point_432021108698906624',
      '/products/switches/wall-embedded-access-point_431909054866522112':'/products/wireless/wall-embedded-access-point_431909054866522112',
      '/products/wireless/data-center-switches_431909019516665856':'/products/switches/data-center-switches_431909019516665856',
      '/products/wireless/enterprise-switches_431909030867501056':'/products/switches/enterprise-switches_431909030867501056',
      '/products/null_p432092720590749696.html':'/products/switches/enterprise-switches_431909030867501056',
      '/products/null_p432203439985131520.html':'/products/switches/enterprise-switches_431909030867501056',
      '/products/null_p432204377660588032.html':'/products/switches/enterprise-switches_431909030867501056',


      "/news/月刊テレコミュニケーション２０２１年１１月号/": "/news/2021-11-01_432105161946562560.html",
      "/news/月刊テレコミュニケーション２０２１年１１月号": "/news/2021-11-01_432105161946562560.html",
      "/news/wi-fi-6対応「rg-ap180ja-jp」 累計販売台数4万台達成について": "/news/2021-03-31_432105022918230016.html",
      "/news/wi-fi-6対応「rg-ap180ja-jp」 累計販売台数4万台達成について/": "/news/2021-03-31_432105022918230016.html",
      "/news/ruijie-networksが日本のデータセンタースイッチ市場に進出": "/news/2021-05-26_432105085109796864.html",
      "/news/ruijie-networksが日本のデータセンタースイッチ市場に進出/": "/news/2021-05-26_432105085109796864.html",
      "/products/": "/products",
      "/solutions/": "/solutions",
      "/case-studies/": "/case-studies",
      "/news/": "/news",
      "/service/": "/service",
      "/service/aftersale-flow/": "/service/aftersale-flow",
      "/service/afterSaleFlow": "/service/aftersale-flow",
      "/service/warrantyPolicy": "/service/product-warranty-policy",
      "/service/product-warranty-policy/": "/service/product-warranty-policy",
      "/service/serviceInfo": "/service/service-info",
      "/service/service-info/": "/service/service-info",
      "/otherForm": "/service/request",
      "/service/request/": "/service/request",
      "/postSale": "/service/post-sales",
      "/service/post-sales/": "/service/post-sales",
      "/company/": "/company",
      "/company/leaderSmessage": "/company/leader",
      "/company/leader/": "/company/leader",
      "/company/profile/": "/company/profile",
      "/about-us/": "/about-us",
      "/about": "/about-us",
      "/about/": "/about-us",
      "/careers/": "/careers",
      "/downloads/": "/downloads",
      "/privacy-policy/": "/privacy-policy",
      "/public/protocol": "/privacy-policy",
      "/presales": "/sales",
      "/sales/": "/sales",
      "/solutions/detail0": "/solutions/japan-cloud-system-solution_431383103847006208.html",
      "/solutions/detail1": "/solutions/100g-400g-ena-data-center-network-solution_431384761587204096.html",
      "/solutions/detail2": "/solutions/mesh-network-solution_432810354147328000.html",
      "/solutions/detail3": "/solutions/configuration-tool-solution_432810359304749056.html",
      "/careers/null_r431723517283401728.html": "/careers/sales0520_r431723517283401728.html",
      "/news/%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9_433712562959548416.html": "/news/address_433712562959548416.html",
      "/news/アクセス_433712562959548416.html": "/news/address_433712562959548416.html",
      // "/news/Interop%20Tokyo%202022_433712652887785472.html": "/news/interop-tokyo-2022_433712652887785472.html",
      // 浏览器自动解析%20为空格
      "/news/Interop Tokyo 2022_433712652887785472.html": "/news/interop-tokyo-2022_433712652887785472.html",
      "/news/Interop-Tokyo-2022_434034990557167616.html": "/news/interop-tokyo-2022-award_434034990557167616.html",
      "/products/xs-s1930j-8gt2sfp_p432111497256108032.html": "/products/rg-xs-s1930j-8gt2sfp-p_p432111497256108032.html",
    },
  },
  {
    response: 'REDREICT',
    originPath: 'Dontcompilepath',
    pathList: [
      '/news/%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9_433712562959548416.html',
    ],
    redirectMap: {
      '/news/%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9_433712562959548416.html': '/news/address_433712562959548416.html'
    }
  },
  {
    response: 'CANONICAL',
    originPath: 'fullPath',
    ip: ipaddress,
    pathList: [
      '/',
      '/news/newsDetail?id=432105085109796864',
      '/products/info?productId=432111497256108032',
      '/products/info?productId=432111650928590848&from=1',
      '/products/info?productId=432112137901965312&from=1',
      '/products/info?productId=432127722311909376&from=1',
      '/products/info?productId=432157640801386496&from=1',
      '/products/info?productId=432157859296575488&from=1',
      '/products/info?productId=432196332852871168&from=1',
      '/products/info?productId=432221793654669312&from=1',
      '/products/info?productId=432334540973015040',
      '/products/info?productId=432585132419842048',
      '/products/info?productId=432585132419842048&from=1',
      '/products/info?productId=432585440843792384',
      '/products/info?productId=432585489170300928',
      '/products/info?productId=432585489170300928&from=1',
      '/products/info?productId=432585532103983104',
      '/products/info?productId=432585618671534080',
      '/products/info?productId=432585618671534080&from=1',
      '/products/info?productId=432585663966609408',
      '/products/info?productId=432629990879657984',
      '/products/info?productId=432629990879657984&from=1',
      '/products/info?productId=432629990879657984&from=2',
      '/products/info?productId=432784353972977664&from=1',
      '/products/info?productId=432784401508073472',
      '/products/info?productId=432784401508073472&from=1',
      '/products/info?productId=432110275305668608',
      '/products/info?productId=432134337127776256',
      '/products/info?productId=432112356253499392',
      '/products/info?productId=432112675328622592',
      '/products/info?productId=432157985548795904',
      '/products/info?productId=432158038448668672',
      '/products/info?productId=432091214928412672',
      '/products/info?productId=432202362292797440',
      '/products/info?productId=432127639865786368',
      '/products/info?productId=432036690761613312',
      '/products/info?productId=432382670957445120',
      '/products/info?productId=432382462915772416',
      '/products/info?productId=432109425563271168',
      '/news/datacenter_434848134891831296.html',
      '/news/datacenter-400zr_434848134891831296.html',
      '/news/Interop Tokyo 2022_434034990557167616.html',
      '/news/interop-tokyo-2022-award_434034990557167616.html'
    ],
    redirectMap: {
      "/news/newsDetail?id=432105085109796864": "/news/2021-05-26_432105085109796864.html",
      "/products/info?productId=432111497256108032": "/products/rg-xs-s1930j-8gt2sfp-p_p432111497256108032.html",
      "/products/info?productId=432111650928590848&from=1": "/products/rg-ap180-pe_p432111650928590848.html",
      "/products/info?productId=432112137901965312&from=1": "/products/rg-s6980-64qc_p432112137901965312.html",
      "/products/info?productId=432127722311909376&from=1": "/products/rg-ap680-cd-jp_p432127722311909376.html",
      "/products/info?productId=432157640801386496&from=1": "/products/rg-s6520-64cq_p432157640801386496.html",
      "/products/info?productId=432157859296575488&from=1": "/products/rg-s6510-48vs8cq_p432157859296575488.html",
      "/products/info?productId=432196332852871168&from=1": "/products/rg-s5750c-48gt4xs-h_p432196332852871168.html",
      "/products/info?productId=432221793654669312&from=1": "/products/rg-eg2100-p-v2_p432221793654669312.html",
      "/products/info?productId=432334540973015040": "/products/rg-ma2810_p432334540973015040.html",
      "/products/info?productId=432585132419842048": "/products/xs-s1930j-8gt2sfp_p432585132419842048.html",
      "/products/info?productId=432585132419842048&from=1": "/products/xs-s1930j-8gt2sfp_p432585132419842048.html",
      "/products/info?productId=432585440843792384": "/products/xs-s1930j-18gt2sfp_p432585440843792384.html",
      "/products/info?productId=432585489170300928": "/products/xs-s1930j-18gt2sfp-p_p432585489170300928.html",
      "/products/info?productId=432585489170300928&from=1": "/products/xs-s1930j-18gt2sfp-p_p432585489170300928.html",
      "/products/info?productId=432585532103983104": "/products/xs-s1930j-24gt4sfp-2gt_p432585532103983104.html",
      "/products/info?productId=432585618671534080": "/products/xs-s1930j-24gt4sfp-2gt-p_p432585618671534080.html",
      "/products/info?productId=432585618671534080&from=1": "/products/xs-s1930j-24gt4sfp-2gt-p_p432585618671534080.html",
      "/products/info?productId=432585663966609408": "/products/xs-s1930j-48gt4sfp_p432585663966609408.html",
      "/products/info?productId=432629990879657984": "/products/rg-ap180-ja-jp_p432629990879657984.html",
      "/products/info?productId=432629990879657984&from=1": "/products/rg-ap180-ja-jp_p432629990879657984.html",
      "/products/info?productId=432629990879657984&from=2": "/products/rg-ap180-ja-jp_p432629990879657984.html",
      "/products/info?productId=432784353972977664&from=1": "/products/rg-ma2610-ac_p432784353972977664.html",
      "/products/info?productId=432784401508073472": "/products/rg-ma2610-pe_p432784401508073472.html",
      "/products/info?productId=432784401508073472&from=1": "/products/rg-ma2610-pe_p432784401508073472.html",
      "/products/info?productId=432110275305668608": "/products/rg-ap180-ac_p432110275305668608.html",
      "/products/info?productId=432134337127776256": "/products/rg-n18000-x_p432134337127776256.html",
      "/products/info?productId=432112356253499392": "/products/rg-s6580-48cq8qc_p432112356253499392.html",
      "/products/info?productId=432112675328622592": "/products/rg-s6920-4c_p432112675328622592.html",
      "/products/info?productId=432157985548795904": "/products/rg-s6510-32cq_p432157985548795904.html",
      "/products/info?productId=432158038448668672": "/products/rg-s6250-48xs8cq_p432158038448668672.html",
      "/products/info?productId=432091214928412672": "/products/rg-s6120-20xs4vs2qxs_p432091214928412672.html",
      "/products/info?productId=432202362292797440": "/products/rg-s2910-24gt4sfp-up-h_p432202362292797440.html",
      "/products/info?productId=432127639865786368": "/products/rg-ap850-i-jpv2_p432127639865786368.html",
      "/products/info?productId=432036690761613312": "/products/rg-ap820-l_p432036690761613312.html",
      "/products/info?productId=432382670957445120": "/products/rg-ws6008_p432382670957445120.html",
      "/products/info?productId=432382462915772416": "/products/rg-eg3230_p432382462915772416.html",
      "/products/info?productId=432109425563271168": "/products/rg-eg3250_p432109425563271168.html",
      "/news/datacenter_434848134891831296.html":"/news/datacenter-400zr_434848134891831296.html",
      "/news/datacenter-400zr_434848134891831296.html":"/news/datacenter-400zr_434848134891831296.html",
      "/news/Interop Tokyo 2022_434034990557167616.html":"/news/interop-tokyo-2022-award_434034990557167616.html",
      "/news/interop-tokyo-2022-award_434034990557167616.html":"/news/interop-tokyo-2022-award_434034990557167616.html",
    }
  }
]

export default seoRouteConfig;
