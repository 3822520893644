<template>
  <div>
    <div class="__nuxt-error-page">
      <div class="error">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="90"
          height="90"
          fill="#DBE1EC"
          viewBox="0 0 48 48"
        >
          <path
            d="M22 30h4v4h-4zm0-16h4v12h-4zm1.99-10C12.94 4 4 12.95 4 24s8.94 20 19.99 20S44 35.05 44 24 35.04 4 23.99 4zM24 40c-8.84 0-16-7.16-16-16S15.16 8 24 8s16 7.16 16 16-7.16 16-16 16z"
          ></path>
        </svg>
        <div class="title">お探しのページは見つかりませんでした</div>
        <div class="link">
          <nuxt-link to="">
            <span @click="back()">前のページへ戻る</span>
          </nuxt-link>
          <el-divider direction="vertical"></el-divider>
          <nuxt-link to="/">ホームページへ戻る</nuxt-link>
        </div>
        <div class="description">{{ message }}</div>
      </div>
      <div class="container">
        <!--pc-->
        <el-menu
          class="el-menu-demo d-md-block d-none"
          mode="horizontal"
          v-if="screenWidth > 750"
        >
          <el-menu-item class="col-lg-2 col-md-6 col-sm-12 isactive">
            <h2 class="news-name">おすすめ製品</h2>
          </el-menu-item>
        </el-menu>
        <keep-alive>
          <div class="news-list d-md-block d-none" v-if="screenWidth > 750">
            <!-- <div class="test"></div> -->
          </div>
        </keep-alive>
        <!--h5-->
        <el-menu
          class="d-md-none d-block"
          mode="horizontal"
          v-if="screenWidth <= 750"
        >
          <el-menu-item class="isactive">
            <div class="news-name">おすすめ製品</div>
          </el-menu-item>
        </el-menu>
        <product-classfy
          :dataList="hotList"
          :secondaryTitle="secondaryTitle"
        ></product-classfy>
      </div>
    </div>
  </div>
</template>

<script>
import ProductClassfy from "@/components/product/productClassfyError";
import reqApi from "@/api/apiUrl";
import { getBanner, getHotProduct } from "@/api/index";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    ProductClassfy,
  },
  name: "error",
  data() {
    return {
      secondaryTitle: "hotProduction",
      activeIndex: "all",
      smList: [],
      hotList: [], //推荐商品
      message: "",
    };
  },
  async asyncData({ query, $axios }) {
    return {
      smList: hotList.data.data || [],
      hotList: newArray,
    };
  },
  computed: {
    ...mapMutations(["setScreenWidth"]),
    ...mapState(["screenWidth"]),
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.$store.commit("setScreenWidth", document.body.clientWidth);
      })();
    };
    this.getHot();
  },
  methods: {
    back() {
      window.history.go(-1);
    },
    async getHot() {
      await getHotProduct()
        .then((res) => {
          if (res.data) {
            this.hotList = res.data;
            for (let i = 0; i < this.hotList.length; i++) {
              if (this.hotList[i].labelValueIds) {
                this.hotList[i].label =
                  this.hotList[i].labelValueIds.split(",");
              }
            }
          }
        })
        .catch((e) => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
::v-deep .container {
  padding: 20px 0;
  .hotProduct {
    padding-top: 2px;
  }
}
.news-name {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  margin-bottom: 0 !important;
}
::v-deep .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu-item {
  text-align: center;
  color: $nav-black-color;
  font-size: 18px;
  letter-spacing: 0.6px;
}
.isactive {
  border-bottom: 3px solid $red-color !important;
  color: black !important;
  font-size: 18px;
  letter-spacing: 0.6px;
}

.news-list {
  margin-top: 64px;
  .news-item {
    display: flex;
    justify-content: flex-start;
    line-height: 24px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 16px;
    color: $nav-black-color;
    /* padding: 24px 0; */
    align-items: center;
    border-bottom: 1px dashed $gray-color;
    &:hover {
      transition: all 0.3s;
      cursor: pointer;
      .span2 {
        text-decoration: underline;
      }
    }
    .span1 {
      width: 140px;
      font-size: 16px;
      color: $nav-defalut-color;
      margin-right: 62px;
    }

    .span3 {
      height: 28px;
      opacity: 0.79;
      border: 2px solid $red-color;
      border-radius: 4px;
      padding: 0;
      font-size: 16px;
      color: #292929;
      width: 180px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 21px;

      &:hover {
        // opacity: 0.79;
        // border: 2px solid #517351;
        // border-radius: 4px;
        // color: #517351;
        // cursor: pointer;
      }
    }

    .span2 {
      line-height: 24px;
      flex: 1;
      font-family: Source Han Sans JP;
      font-weight: 400;
      font-size: 16px;
      color: #292929;

      &:hover {
        // text-decoration: underline;
        // transition: all 0.3s;
        // cursor: pointer;
      }
    }
  }
}
.sm-news {
  margin-top: 34px;
  .item {
    display: flex;
    justify-content: space-between;
    line-height: 24px;
    font-family: Source Han Sans JP;
    font-weight: 400;
    font-size: 16px;
    color: $nav-black-color;
    padding: 24px 0;
    align-items: center;
    border-bottom: 1px dashed $gray-color;
    &:hover {
      transition: all 0.3s;
      cursor: pointer;
      .span2 {
        text-decoration: underline;
      }
    }
    .left-div {
      padding-right: 10px;
    }
    .div1 {
      display: flex;
      justify-content: flex-start;
    }
    .span1 {
      /*width: 140px;*/
      margin-right: 20px;
      font-size: 16px;
      color: $nav-defalut-color;
    }
    .span2 {
      line-height: 24px;
      flex: 1;
      margin-top: 10px;
      font-size: 16px;
      color: #292929;
      &:hover {
        text-decoration: underline;
        transition: all 0.3s;
        cursor: pointer;
      }
    }
    img {
      width: 8px;
      height: 16px;
      margin-right: 10px;
    }
    .span3 {
      width: 180px;
      height: 28px;
      opacity: 0.79;
      border: 2px solid $red-color;
      border-radius: 4px;
      padding: 0;
      font-size: 16px;
      color: #292929;
      // width: 180px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 21px;

      &:hover {
        // opacity: 0.79;
        // border: 2px solid #517351;
        // border-radius: 4px;
        // color: #517351;
        // cursor: pointer;
      }
    }
  }
}
.a_link {
  color: #7d7d7d;
  font-weight: 400;
}
.el-breadcrumb__item {
  line-height: 15px;
}
@media (max-width: 750px) {
  .col-lg-2,
  .col-md-6,
  .col-sm-12 {
    width: 50% !important;
    padding: 0 15px !important;
  }
  .el-menu--horizontal {
    /*justify-content: flex-start;*/
    flex-wrap: wrap;
  }
}
.hotProduction {
  width: 1000px;
  position: relative;
}
.line {
  /* display: block; */
  position: absolute;
  width: 100px;
  top: 45px;
  border-bottom: 3px solid #e60033;
}
.__nuxt-error-page {
  margin-top: 200px;
  color: #47494e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 780px; */
}

.__nuxt-error-page .error {
  max-width: 450px;
  text-align: center;
}

.__nuxt-error-page .title {
  font-size: 24px;
  font-size: 1.5rem;
  margin-top: 15px;
  color: #47494e;
  margin-bottom: 8px;
}
.__nuxt-error-page .select {
  font-size: 16px;
  font-size: 0.83rem;
  margin-top: 30px;
}
.__nuxt-error-page .link {
  font-size: 14px;
  font-size: 0.63rem;
  margin-top: 15px;
}

.__nuxt-error-page .description {
  color: #7f828b;
  line-height: 21px;
  margin-bottom: 10px;
}
</style>
